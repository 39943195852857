'use client'

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Cell, Divider, Image, Navigation, Icon } from '@vinted/web-ui'
import { ArrowLeft24, Dots24 } from '@vinted/monochrome-icons'

import ScrollableArea from 'components/ScrollableArea'
import FilterDropdown from 'components/FilterDropdown'
import SelectableItemList from 'components/SelectableItemList'

import useCatalogFilter from 'pages/Catalog/hooks/useCatalogFilter'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import { getCatalogIds, getCatalogMap } from 'state/catalog-filters/selectors'
import { actions } from 'state/catalog-filters/slice'

import { CatalogAttribute, ROOT_CATALOG_ID, WEB_CATALOG_ROOT_ALL_CODE } from 'constants/catalog'
import { CatalogModel } from 'types/models'
import { RenderItemProps } from 'components/SelectableItemList/SelectableItemList'
import { CatalogItem, CatalogItemData } from 'types/catalog-filters'
import { getCatalogPhotoSrc } from 'data/utils/catalog'
import { selectCatalogEvent } from 'libs/common/event-tracker/events'

const DROPDOWN_MIN_WIDTH = 300
const SELECT_DROPDOWN_MAX_HEIGHT = 320

const CatalogFilter = () => {
  const dispatch = useDispatch()
  const translate = useTranslate('catalog.filters')
  const { track } = useTracking()
  const catalogs = useSelector(getCatalogMap)
  const selectedIds = useSelector(getCatalogIds)
  const [navigatedCatalogId, setNavigatedCatalogId] = useState<number>(ROOT_CATALOG_ID)
  const [openedCatalogsIds, setOpenedCatalogsIds] = useState<Array<number>>([ROOT_CATALOG_ID])
  const [isCatalogForceClose, setIsCatalogForceClose] = useState<boolean>(false)
  const { allShownCatalogs } = useCatalogFilter(
    openedCatalogsIds,
    translate('catalog.all'),
    catalogs,
  )

  const handleOnClose = () => {
    if (isCatalogForceClose) setIsCatalogForceClose(false)
  }

  const closeCatalog = (catalogId: number) => {
    setOpenedCatalogsIds(prevIds => prevIds.filter(prevId => prevId !== catalogId))
  }

  const openCatalog = (catalogId: number) => {
    setOpenedCatalogsIds(prevIds => [...prevIds, catalogId])
  }

  const handleBackClick = (catalog: CatalogModel) => () => {
    setNavigatedCatalogId(Number(catalog.parentId))
    closeCatalog(catalog.id)
  }

  const trackCatalogSelection = (catalogIds: Array<number>) => {
    track(
      selectCatalogEvent({
        catalogIds,
        attributeId: CatalogAttribute.Filter,
      }),
    )
  }

  const handleCatalogClick = (catalog: CatalogItem) => {
    if (!catalog.data) return

    const { catalogIds, code } = catalog.data
    const catalogId = Number(catalog.id)

    if (catalogIds.length > 0 && code !== WEB_CATALOG_ROOT_ALL_CODE) {
      setNavigatedCatalogId(catalogId)
      openCatalog(catalogId)

      return
    }

    let newSelectedIds: Array<number> = []

    if (selectedIds.includes(catalogId)) {
      newSelectedIds = selectedIds.filter(id => id !== catalogId)
    } else {
      newSelectedIds = [catalogId]
    }

    trackCatalogSelection(newSelectedIds)

    dispatch(actions.catalogFilterSet({ ids: newSelectedIds }))

    setIsCatalogForceClose(true)
  }

  const renderNavigation = () => {
    const catalog = catalogs[navigatedCatalogId]

    if (!catalog) return null

    return (
      <>
        <Navigation
          left={
            <Button
              aria={{
                'aria-label': translate('a11y.actions.back'),
              }}
              styling={Button.Styling.Flat}
              theme="amplified"
              icon={<Icon name={ArrowLeft24} />}
              onClick={handleBackClick(catalog)}
              testId="catalog-filter-go-back"
            />
          }
          body={catalog.title}
        />
        <Divider />
      </>
    )
  }

  const renderIcon = (photo: CatalogModel['photo'], code: string, depth: number) => {
    if (depth > 1) return null

    if (code === WEB_CATALOG_ROOT_ALL_CODE) {
      return <Icon name={Dots24} color={Icon.Color.Primary} />
    }

    if (!photo) return null

    return <Image {...getCatalogPhotoSrc(photo)} size={Image.Size.Regular} />
  }

  const renderCatalog = ({ item, itemElementProps }: RenderItemProps<CatalogItemData>) => {
    if (!item.data) return null

    const { catalogIds, code, depth, photo } = item.data
    const icon = renderIcon(photo, code, depth)

    if (!catalogIds.length || code === WEB_CATALOG_ROOT_ALL_CODE) {
      return <Cell {...itemElementProps} prefix={icon} />
    }

    return <Cell {...itemElementProps} prefix={icon} suffix={null} chevron />
  }

  const renderDropdown = () => {
    const shownCatalogs = allShownCatalogs[navigatedCatalogId]

    return (
      <div className="u-ui-padding-vertical-x-small">
        {renderNavigation()}

        <ScrollableArea maxHeight={SELECT_DROPDOWN_MAX_HEIGHT}>
          <SelectableItemList
            name="catalog_ids"
            isMultiSelect={false}
            items={shownCatalogs}
            selected={selectedIds}
            onItemClick={handleCatalogClick}
            renderItem={renderCatalog}
            disableButtonClicks
          />
        </ScrollableArea>
      </div>
    )
  }

  return (
    <div className="u-ui-margin-right-regular u-ui-margin-bottom-regular">
      <FilterDropdown
        title={translate('catalog.name')}
        minWidth={DROPDOWN_MIN_WIDTH}
        areAnyOfItemsSelected={!!selectedIds.length}
        testId="catalog--catalog-filter"
        useChipAsTrigger
        useForceClose={isCatalogForceClose}
        onClose={handleOnClose}
      >
        {renderDropdown()}
      </FilterDropdown>
    </div>
  )
}

export default CatalogFilter
