import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import useTracking from 'hooks/useTracking'
import useFetchVasEntryPoints from 'hooks/useFetchVasEntryPoints'
import useFetch from 'hooks/useFetch'

import { VasEntryPoint } from 'constants/vas-entry-point'
import { ClosetModel, VasEntryPointModel } from 'types/models'
import { UiState } from 'constants/ui'
import { getCatalogItemCurrentPage, getCatalogItemUiState } from 'state/items/selectors'
import {
  getCatalogUiState,
  getDynamicFiltersUiState,
  getFilters,
  getGlobalSearchSessionId,
  getSearchCorrelationId,
  getSearchSessionId,
  getSelectedDynamicFilters,
} from 'state/catalog-filters/selectors'
import { impressionEvent } from 'libs/common/event-tracker/events'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ContentSource } from 'constants/tracking/content-sources'
import { getClosetPromotions } from 'data/api'
import { transformClosetPromotionsResponse } from 'data/api/transformers/response'
import { Screen } from 'constants/tracking/screens'
import { CLOSET_PROMOTIONS_COUNT, CLOSET_PROMOTIONS_ITEM_COUNT } from 'constants/index'

const useClosetPromotion = () => {
  const { track } = useTracking()

  const uiState = useSelector(getCatalogItemUiState)
  const catalogUiState = useSelector(getCatalogUiState)
  const searchCorrelationId = useSelector(getSearchCorrelationId)
  const searchSessionId = useSelector(getSearchSessionId)
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const filters = useSelector(getFilters)
  const selectedDynamicFilters = useSelector(getSelectedDynamicFilters)
  const currentPage = useSelector(getCatalogItemCurrentPage)
  const dynamicFiltersUiState = useSelector(getDynamicFiltersUiState)

  const seenClosetIds = useRef<Array<number>>([])
  const isPaginationEventRef = useRef(false)
  const isFilterChangeEventRef = useRef(false)
  const hasSearchSessionIdChangedRef = useRef(false)

  useEffect(() => {
    isPaginationEventRef.current = true
  }, [currentPage])

  useEffect(() => {
    isFilterChangeEventRef.current = true
  }, [filters, selectedDynamicFilters])

  useEffect(() => {
    hasSearchSessionIdChangedRef.current = true
  }, [searchSessionId])

  const { fetch: fetchClosetPromotions, transformedData: closets } = useFetch(
    getClosetPromotions,
    transformClosetPromotionsResponse,
  )

  useEffect(() => {
    if (uiState !== UiState.Success) return
    if (dynamicFiltersUiState !== UiState.Success) return
    if (!isFilterChangeEventRef.current && !isPaginationEventRef.current) return
    if (isFilterChangeEventRef.current && !hasSearchSessionIdChangedRef.current) return

    const determineExcludedUserIds = () => {
      if (isFilterChangeEventRef.current) {
        seenClosetIds.current = []
      }

      return seenClosetIds.current
    }

    fetchClosetPromotions({
      excludedUserIds: determineExcludedUserIds(),
      perPage: CLOSET_PROMOTIONS_COUNT,
      itemCount: CLOSET_PROMOTIONS_ITEM_COUNT,
      screenName: Screen.Catalog,
      searchSessionId,
      selectedDynamicFilters,
      ...filters,
    })

    if (isFilterChangeEventRef.current) isFilterChangeEventRef.current = false
    if (isPaginationEventRef.current) isPaginationEventRef.current = false
    hasSearchSessionIdChangedRef.current = false
  }, [
    uiState,
    dynamicFiltersUiState,
    fetchClosetPromotions,
    searchSessionId,
    filters,
    selectedDynamicFilters,
  ])

  const { data: vasEntryPoints } = useFetchVasEntryPoints([VasEntryPoint.PromotedClosets])

  const closetPromoBanner = vasEntryPoints?.find(
    (entryPoint: VasEntryPointModel) => entryPoint.name === VasEntryPoint.PromotedClosets,
  )

  const handleClosetPromoVisibility = (closet: ClosetModel, position: number) => {
    const {
      user: { id },
    } = closet

    const isLoading = uiState === UiState.Pending || catalogUiState === UiState.Pending

    if (isLoading) return

    if (seenClosetIds.current.includes(id)) return

    track(
      impressionEvent({
        id,
        position,
        contentType: ListItemContentType.PromotedCloset,
        contentSource: ContentSource.PromotedClosets,
        searchSessionId,
        searchCorrelationId,
        globalSearchSessionId,
      }),
    )

    seenClosetIds.current.push(id)
  }

  const getClosetListWithBanner = (closetList?: Array<ClosetModel>) => {
    if (!closetList?.length || !closetList) return []

    const closetListClone = [...closetList]

    if (closetListClone[0]) {
      closetListClone[0] = { ...closetListClone[0] }
      closetListClone[0].showBanner = true
    }

    return closetListClone
  }

  const closetsWithBanner = getClosetListWithBanner(closets)

  return { closetsWithBanner, closetPromoBanner, handleClosetPromoVisibility }
}

export default useClosetPromotion
