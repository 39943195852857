'use client'

import { ReactNode } from 'react'
import { Cell, Text } from '@vinted/web-ui'

import List from 'components/List'
import { FilterCatalogModel, FilterCatalogModelWithFilterGroups } from 'types/models/filter-catalog'
import { FilterGroupModel } from 'types/models/filter-group'

type Props<T> = {
  catalogs: Array<FilterCatalogModel> | Array<FilterCatalogModelWithFilterGroups<T>>
  onItemClick: (catalog: FilterCatalogModel | FilterCatalogModelWithFilterGroups<T>) => void
  renderSuffix?: (filterGroups: Array<FilterGroupModel<T>>) => ReactNode
}

const FilterCatalogList = <T,>({ catalogs, onItemClick, renderSuffix }: Props<T>) => {
  const handleClick =
    (catalog: FilterCatalogModel | FilterCatalogModelWithFilterGroups<string>) => () => {
      onItemClick(catalog)
    }

  const getCatalogSuffix = (
    catalog: FilterCatalogModel | FilterCatalogModelWithFilterGroups<T>,
  ) => {
    if (!renderSuffix) return null
    if (!('matchingGroups' in catalog)) return null

    return renderSuffix(catalog.matchingGroups)
  }

  const renderCatalog = (
    catalog: FilterCatalogModel | FilterCatalogModelWithFilterGroups<string>,
  ) => {
    const suffix = getCatalogSuffix(catalog)

    return (
      <Cell
        key={catalog.code}
        type={Cell.Type.Navigating}
        body={<Text text={catalog.title} type={Text.Type.Title} />}
        suffix={suffix}
        chevron={!suffix}
        onClick={handleClick(catalog)}
      />
    )
  }

  return <List>{catalogs.map(renderCatalog)}</List>
}

export default FilterCatalogList
