import { ReactNode } from 'react'
import { Text } from '@vinted/web-ui'

type Props = {
  textTheme?: ComponentProps<typeof Text>['theme']
  suffixText?: ReactNode
  renderSuffix: () => ReactNode
}

const FilterCellSuffix = ({ suffixText, renderSuffix, textTheme }: Props) => {
  return (
    <div className="u-flexbox u-align-items-center">
      {!!suffixText && (
        <span className="filter-cell__suffix-text" data-test-id="selected-filters">
          <Text text={suffixText} theme={textTheme} truncate />
        </span>
      )}
      {renderSuffix()}
    </div>
  )
}

export default FilterCellSuffix
