'use client'

import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Button } from '@vinted/web-ui'

import { State as AppState } from 'state/types'
import { actions } from 'state/catalog-filters/slice'
import * as selectors from 'state/catalog-filters/selectors'

const mapStateToProps = (state: AppState) => ({
  filters: selectors.getSelectedFilterNamesWithoutQuery(state),
  selectedDynamicFilters: selectors.getSelectedDynamicFilters(state),
})

const mapActionsToProps = {
  removeFilters: actions.removeFilters,
  removeDynamicFilters: actions.removeSelectedDynamicFilters,
}

const connector = connect(mapStateToProps, mapActionsToProps)

type Props = ConnectedProps<typeof connector>

class FilterReset extends Component<Props> {
  handleClick = () => {
    const { removeFilters, removeDynamicFilters, filters } = this.props

    removeFilters({ filters })
    removeDynamicFilters()
  }

  render() {
    const { filters, selectedDynamicFilters } = this.props

    if (
      !filters.length &&
      !selectedDynamicFilters.some(selectedFilter => {
        if (selectedFilter.ids.length) return true

        return false
      })
    )
      return null

    return (
      <Button
        onClick={this.handleClick}
        text={<FormattedMessage id="catalog.reset_filters" />}
        styling={Button.Styling.Flat}
        size={Button.Size.Small}
        testId="catalog-reset-filters-button"
      />
    )
  }
}

export { FilterReset }
export default connector(FilterReset)
