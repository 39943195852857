'use client'

import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Chip, Text, Divider, Icon } from '@vinted/web-ui'
import { Filters16, X24 } from '@vinted/monochrome-icons'

import SeparatedList from 'components/SeparatedList'
import {
  getCatalogIds,
  getSortBy,
  getCatalogMap,
  getPriceFrom,
  getPriceTo,
  getSearchSessionId,
  getGlobalSearchSessionId,
  getDynamicFilters,
  getSelectedDynamicFilters,
  getTemporarySelectedDynamicFilters,
} from 'state/catalog-filters/selectors'
import { actions } from 'state/catalog-filters/slice'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import { SortByOption } from 'constants/filter'
import { reloadPage } from 'libs/utils/window'
import {
  abTestExposeEvent,
  clickEvent,
  clickFilterEvent,
  viewFilterEvent,
} from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { Filter } from 'constants/tracking/filters'
import { DisplayType, FilterModalNames, OptionType, SelectionType } from 'constants/dynamic-filters'
import { DynamicFilterModel, ExperimentModel } from 'types/models/dynamic-filter'
import { PriceRange } from 'state/catalog-filters/types'

import { getExposee } from 'state/session/selectors'

import ListFilterModal from 'components/DynamicFilters/ListFilter/ListFilterModal'
import SearchFilterModal from 'components/DynamicFilters/SearchFilter/SearchFilterModal'
import GridFilterModal from 'components/DynamicFilters/GridFilter/GridFilterModal'
import FilterModal from 'components/FilterModal'
import FilterModalNavigation from 'components/FilterModalNavigation'

import SortFilter from './SortFilter'
import CatalogFilter from './CatalogFilter'
import PriceFilter from './PriceFilter'

type Props = {
  learnMoreUrl: string | null
}

type SharedFilterProps = {
  isFilterCellVisible: boolean
  onModalOpen: () => void
  onModalClose: () => void
}

const MobileFilters = ({ learnMoreUrl }: Props) => {
  const translate = useTranslate('catalog.filters')
  const dispatch = useDispatch()
  const { track } = useTracking()
  const exposee = useSelector(getExposee)

  const allCatalogs = useSelector(getCatalogMap)
  const appliedCatalogsIds = useSelector(getCatalogIds)
  const appliedSortOption = useSelector(getSortBy)
  const appliedPriceFrom = useSelector(getPriceFrom)
  const appliedPriceTo = useSelector(getPriceTo)
  const searchSessionId = useSelector(getSearchSessionId)
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)

  const dynamicFilters = useSelector(getDynamicFilters)
  const selectedDynamicFilters = useSelector(getSelectedDynamicFilters)
  const temporarySelectedDynamicFilters = useSelector(getTemporarySelectedDynamicFilters)

  const [modalName, setModalName] = useState<string>(FilterModalNames.Default)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAnyFilterModalOpen, setIsAnyFilterModalOpen] = useState(false)
  const [selectedSortOption, setSelectedSortOption] = useState<SortByOption | null | undefined>(
    appliedSortOption,
  )

  const [selectedCatalogsIds, setSelectedCatalogsIds] = useState(appliedCatalogsIds)
  const [selectedPriceRange, setSelectedPriceRange] = useState({
    priceFrom: appliedPriceFrom,
    priceTo: appliedPriceTo,
  })

  const shownFilters = useRef<Array<string>>([])

  useEffect(() => {
    window.addEventListener('popstate', reloadPage)

    return () => {
      window.removeEventListener('popstate', reloadPage)
    }
  }, [])

  useEffect(() => {
    if (modalName !== FilterModalNames.Root) return

    dynamicFilters.forEach(filter => {
      if (filter.isHidden && filter.experiment) {
        track(abTestExposeEvent({ ...exposee, ...filter.experiment }))
      }
    })
  }, [modalName, dynamicFilters, exposee, track])

  const clearSelectedFilters = () => {
    setSelectedSortOption(null)
    setSelectedCatalogsIds([])
    setSelectedPriceRange({ priceFrom: null, priceTo: null })

    dispatch(actions.removeTemporarySelectedDynamicFilters())
    dispatch(actions.removeTemporaryCatalogIds())
    dispatch(actions.removeTemporaryPriceRange())
  }

  const resetSelectedFilters = () => {
    setSelectedSortOption(appliedSortOption)
    setSelectedCatalogsIds(appliedCatalogsIds)
    setSelectedPriceRange({
      priceFrom: appliedPriceFrom,
      priceTo: appliedPriceTo,
    })

    dispatch(actions.removeTemporarySelectedDynamicFilters())
    dispatch(actions.updateTemporaryCatalogIds({ temporaryCatalogIds: appliedCatalogsIds }))
  }

  const updateTemporarySelectedFilters = () => {
    dispatch(
      actions.setTemporarySelectedDynamicFilters({
        temporarySelectedDynamicFilters: selectedDynamicFilters,
      }),
    )

    dispatch(actions.updateTemporaryCatalogIds({ temporaryCatalogIds: appliedCatalogsIds }))
  }

  const updateSelectedDynamicFilters = () => {
    dispatch(
      actions.setSelectedDynamicFilters({
        selectedDynamicFilters: temporarySelectedDynamicFilters,
      }),
    )
  }

  const openModal = () => {
    setModalName(FilterModalNames.Root)
    setIsModalOpen(true)

    updateTemporarySelectedFilters()
  }

  const closeModal = () => {
    setModalName(FilterModalNames.Default)
    setIsModalOpen(false)

    shownFilters.current = []
  }

  const trackCancelClick = () => {
    track(
      clickEvent({
        target: ClickableElement.CancelFilters,
        screen: Screen.MobileCatalogFilters,
      }),
    )
  }

  const handleSubmit = () => {
    closeModal()

    dispatch(
      actions.changeFilters({
        filters: {
          ...selectedPriceRange,
          sortBy: selectedSortOption,
          catalogIds: selectedCatalogsIds,
        },
      }),
    )

    updateSelectedDynamicFilters()
  }

  const handleSubmitWithTracking = () => {
    handleSubmit()

    track(
      clickEvent({
        target: ClickableElement.ApplyFilters,
        screen: Screen.MobileCatalogFilters,
      }),
    )
  }

  const trackFilterClick = (filter: Filter, type?: string) => () => {
    setModalName(`${FilterModalNames.Filter}_${filter}${type ? `_${type}` : ''}`)

    track(
      clickFilterEvent({
        filter,
        screen: Screen.MobileCatalogFilters,
        searchSessionId,
        globalSearchSessionId,
        type,
        code: type,
      }),
    )
  }

  const trackClearFiltersClick = () => {
    track(
      clickEvent({
        target: ClickableElement.ClearFilters,
        screen: Screen.MobileCatalogFilters,
      }),
    )
  }

  const trackFilterView = (filter: Filter, type?: string, experiment?: ExperimentModel) => {
    track(
      viewFilterEvent({
        filter,
        screen: Screen.MobileCatalogFilters,
        searchSessionId,
        globalSearchSessionId,
        type,
        code: type,
      }),
    )

    if (experiment) {
      track(abTestExposeEvent({ ...exposee, ...experiment }))
    }
  }

  const trackFilterCellClick = () => {
    track(
      clickEvent({
        target: ClickableElement.SelectFilters,
      }),
    )
  }

  const handleFilterCellView =
    (filter: Filter, type?: string, experiment?: ExperimentModel) => () => {
      const shownFilter = type || filter

      if (shownFilters.current.includes(shownFilter)) return

      shownFilters.current.push(shownFilter)

      trackFilterView(filter, type, experiment)
    }

  const handleFilterCellClick = () => {
    openModal()
    trackFilterCellClick()
  }

  const handlePrefixClick = () => {
    closeModal()
    resetSelectedFilters()
    trackCancelClick()
  }

  const handleSuffixClick = () => {
    clearSelectedFilters()
    trackClearFiltersClick()
  }

  const handleCatalogSelect = (newCatalogIds: Array<number>) => {
    setSelectedCatalogsIds(newCatalogIds)

    dispatch(actions.setTemporaryCatalogIds({ temporaryCatalogIds: newCatalogIds }))
  }

  const handlePriceRange = (range: PriceRange) => {
    setSelectedPriceRange(range)

    dispatch(actions.setTemporaryPriceRange({ priceRange: range }))
  }

  const handleDynamicFilterToggle = (
    id: number,
    type: string,
    selectionType: SelectionType,
    selectedIds: Array<number>,
  ) => {
    if (!selectedIds.includes(id)) {
      dispatch(
        actions.setTemporarySelectedDynamicFilter({
          temporarySelectedDynamicFilter: {
            type,
            ids: selectionType === SelectionType.Single ? [id] : [...selectedIds, id],
          },
        }),
      )
    } else if (selectionType === SelectionType.Multi) {
      dispatch(
        actions.removeTemporarySelectedDynamicFilterId({
          type,
          id,
        }),
      )
    }
  }

  const clearDynamicFilterOptions = (type: string, ids?: Array<number>) => {
    if (ids?.length) {
      dispatch(actions.removeTemporarySelectedDynamicFilterIds({ type, ids }))
    } else {
      dispatch(actions.removeTemporarySelectedDynamicFilter({ type }))
    }
  }

  const handleAnyFilterModalOpen = () => {
    setIsAnyFilterModalOpen(true)
  }

  const handleAnyFilterModalClose = () => {
    setModalName(FilterModalNames.Root)
    shownFilters.current = []
    setIsAnyFilterModalOpen(false)
  }

  const getTemporarySelectedFilter = (type: string) =>
    temporarySelectedDynamicFilters.find(filter => filter.type === type)

  const getRootOptionType = (dynamicFilter: DynamicFilterModel) =>
    dynamicFilter.options.find(option => !!option.type)?.type || OptionType.Default

  const renderModalNavigation = () => (
    <FilterModalNavigation
      title={translate('mobile.filters_screen.title')}
      icon={<Icon name={X24} />}
      prefixAriaLabel={translate('a11y.actions.close')}
      suffixText={translate('mobile.filters_screen.actions.clear_filters')}
      testId="modal-navigation"
      onPrefixClick={handlePrefixClick}
      onSuffixClick={handleSuffixClick}
    />
  )

  const renderDynamicFilter = (filter: DynamicFilterModel, sharedProps: SharedFilterProps) => {
    const temporarySelectedDynamicFilter = getTemporarySelectedFilter(filter.type)
    const key = `${filter.type}-${filter.id}`

    switch (filter.displayType) {
      case DisplayType.List: {
        return (
          <ListFilterModal
            key={key}
            type={filter.type}
            title={filter.title}
            selectionType={filter.selectionType}
            isSelectionHighlighted={filter.isSelectionHighlighted}
            isNewFilter={filter.isNewFilter}
            options={filter.options}
            fromScreen={Screen.MobileCatalogFilters}
            isHidden={filter.isHidden}
            rootOptionType={getRootOptionType(filter)}
            onSubmit={handleSubmit}
            onFilterCellClick={trackFilterClick(Filter.Dynamic, filter.type)}
            onFilterCellView={handleFilterCellView(Filter.Dynamic, filter.type, filter.experiment)}
            selectedIds={temporarySelectedDynamicFilter?.ids || []}
            clearSelectedOptions={clearDynamicFilterOptions}
            onListOptionSelect={id => {
              handleDynamicFilterToggle(
                id,
                filter.type,
                filter.selectionType,
                temporarySelectedDynamicFilter?.ids || [],
              )
            }}
            {...sharedProps}
          />
        )
      }
      case DisplayType.ListSearch: {
        return (
          <SearchFilterModal
            key={key}
            type={filter.type}
            title={filter.title}
            selectionType={filter.selectionType}
            isSelectionHighlighted={filter.isSelectionHighlighted}
            options={filter.options}
            fromScreen={Screen.MobileCatalogFilters}
            translations={filter.searchTranslations}
            isHidden={filter.isHidden}
            rootOptionType={getRootOptionType(filter)}
            onSubmit={handleSubmit}
            onFilterCellClick={trackFilterClick(Filter.Dynamic, filter.type)}
            onFilterCellView={handleFilterCellView(Filter.Dynamic, filter.type, filter.experiment)}
            selectedIds={temporarySelectedDynamicFilter?.ids || []}
            clearSelectedOptions={clearDynamicFilterOptions}
            onListOptionSelect={id => {
              handleDynamicFilterToggle(
                id,
                filter.type,
                filter.selectionType,
                temporarySelectedDynamicFilter?.ids || [],
              )
            }}
            {...sharedProps}
          />
        )
      }
      case DisplayType.Grid: {
        return (
          <GridFilterModal
            key={key}
            type={filter.type}
            title={filter.title}
            isSelectionHighlighted={filter.isSelectionHighlighted}
            isNewFilter={filter.isNewFilter}
            options={filter.options}
            isHidden={filter.isHidden}
            fromScreen={Screen.MobileCatalogFilters}
            rootOptionType={getRootOptionType(filter)}
            onSubmit={handleSubmit}
            onFilterCellClick={trackFilterClick(Filter.Dynamic, filter.type)}
            onFilterCellView={handleFilterCellView(Filter.Dynamic, filter.type, filter.experiment)}
            selectedIds={temporarySelectedDynamicFilter?.ids || []}
            clearSelectedOptions={clearDynamicFilterOptions}
            onListOptionSelect={id => {
              handleDynamicFilterToggle(
                id,
                filter.type,
                filter.selectionType,
                temporarySelectedDynamicFilter?.ids || [],
              )
            }}
            {...sharedProps}
          />
        )
      }
      case DisplayType.HybridPrice: {
        return (
          <PriceFilter
            key={key}
            selectedPriceRange={selectedPriceRange}
            minimumValidation={filter.minimumValidation}
            onPriceRangeChange={handlePriceRange}
            onSubmit={handleSubmitWithTracking}
            onFilterCellClick={trackFilterClick(Filter.Price)}
            onFilterCellView={handleFilterCellView(Filter.Price)}
            {...sharedProps}
          />
        )
      }
      default:
        return null
    }
  }

  const renderDynamicFilters = (sharedProps: SharedFilterProps) => {
    return dynamicFilters.map(filter => renderDynamicFilter(filter, sharedProps))
  }

  const renderFilters = () => {
    const sharedProps = {
      isFilterCellVisible: !isAnyFilterModalOpen,
      onModalOpen: handleAnyFilterModalOpen,
      onModalClose: handleAnyFilterModalClose,
    }

    return (
      <SeparatedList separator={<Divider />}>
        <CatalogFilter
          allCatalogs={allCatalogs}
          selectedCatalogsIds={selectedCatalogsIds}
          onCatalogSelect={handleCatalogSelect}
          onSubmit={handleSubmitWithTracking}
          onFilterCellClick={trackFilterClick(Filter.Category)}
          onFilterCellView={handleFilterCellView(Filter.Category)}
          {...sharedProps}
        />
        {renderDynamicFilters(sharedProps)}
        <SortFilter
          learnMoreUrl={learnMoreUrl}
          selectedOption={selectedSortOption}
          onOptionClick={setSelectedSortOption}
          onSubmit={handleSubmitWithTracking}
          onFilterCellClick={trackFilterClick(Filter.Sort)}
          onFilterCellView={handleFilterCellView(Filter.Sort)}
          {...sharedProps}
        />
      </SeparatedList>
    )
  }

  const getAppliedFiltersCount = () => {
    let count = 0

    if (appliedCatalogsIds.length) count += 1
    if (appliedSortOption && appliedSortOption !== SortByOption.Relevance) count += 1
    if (appliedPriceFrom || appliedPriceTo) count += 1

    selectedDynamicFilters.forEach(filter => {
      if (filter?.ids.length) count += 1
    })

    return count
  }

  const appliedFiltersCount = getAppliedFiltersCount()

  return (
    <>
      <Chip
        prefix={<Icon name={Filters16} />}
        text={translate('mobile.filters_chip_text', {
          count: appliedFiltersCount ? `(${appliedFiltersCount})` : null,
        })}
        textType={Text.Type.Subtitle}
        activated={!!appliedFiltersCount}
        onClick={handleFilterCellClick}
        testId="trigger"
      />
      <FilterModal
        isOpen={isModalOpen}
        testId="modal"
        renderNavigation={renderModalNavigation}
        onSubmit={handleSubmitWithTracking}
      >
        {renderFilters()}
        <Divider />
      </FilterModal>
    </>
  )
}

export default MobileFilters
