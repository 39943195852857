'use client'

import { useSelector } from 'react-redux'
import { Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

import {
  getSelectedCatalogs,
  getIsPopularInCatalog,
  getCatalogTitle,
  getCatalogBrand,
} from 'state/catalog-filters/selectors'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

type Props = {
  truncate?: boolean
}

const Title = ({ truncate = false }: Props) => {
  const translate = useTranslate()

  const catalogs = useSelector(getSelectedCatalogs)
  const brand = useSelector(getCatalogBrand)
  const isPopularCatalog = useSelector(getIsPopularInCatalog)
  const catalogTitle = useSelector(getCatalogTitle)
  const catalogTitleFromResponseEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_catalog_title_from_response'),
  )

  const titlesJoinElement = ' '

  function getPopularCatalogTitle() {
    const popularCatalogTitle = translate('homepage.popular_items.catalog_title')

    if (!brand) return popularCatalogTitle

    return [popularCatalogTitle, brand.title].join(titlesJoinElement)
  }

  function getTitle() {
    const title: Array<string> = []

    if (catalogs.length === 1) {
      title.push(catalogs[0].title)
    }

    if (brand) {
      title.push(brand.title)
    }

    if (title.length) return title.join(titlesJoinElement)

    return translate('catalog.items')
  }

  function getText() {
    if (catalogTitleFromResponseEnabled && isPopularCatalog) {
      return catalogTitle || getTitle()
    }

    return isPopularCatalog ? getPopularCatalogTitle() : getTitle()
  }

  return <Text type={Text.Type.Heading} text={getText()} truncate={truncate} as="h1" />
}

export default Title
