'use client'

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider, EmptyState, Loader, Spacer } from '@vinted/web-ui'

import { getIsCatalogModerator, getIsCatalogRoleMarketingPhotos } from 'state/session/selectors'

import { SearchSubscribeButton } from 'components/SavedSearches'
import ErrorBoundary from 'components/ErrorBoundary'
import ShippingFeesAppliedBanner from 'components/ShippingFeesApplyBanner'
import useBreakpoint from 'hooks/useBreakpoint'
import { AdShape } from 'constants/ads'
import Advertisement from 'components/Advertisement'

import { actions } from 'state/catalog-filters/slice'
import { getCatalogUiState } from 'state/catalog-filters/selectors'
import { UiState } from 'constants/ui'
import { FormattedMessage } from 'components/@support'
import { BrandModel, CatalogModel } from 'types/models'
import { GoogleTagManagerEvent } from 'constants/google'
import { googleTagManagerTrack } from 'data/utils/google'

import useLocation from 'hooks/useLocation/useLocation'

import Breadcrumbs from './Breadcrumbs'
import Title from './Title'
import Filters from './Filters'
import Marketing from './Marketing'
import SendToAlerts from './SendToAlerts'
import Items from './Items'
import Pagination from './Pagination'
import SelectedFilters from './SelectedFilters'
import SubcatalogNavigation from './SubcatalogNavigation'
import MobileFilters from './MobileFilters'
import ResultRanking from './ResultRanking'
import { logCatalogError } from './utils/log'
import { CatalogFeature } from './constants'
import BrandButton from './BrandButton'

type Props = {
  learnMoreUrl: string | null
  brand?: BrandModel
  catalog?: CatalogModel
}

const Catalog = ({ learnMoreUrl, brand, catalog }: Props) => {
  const breakpoints = useBreakpoint()
  const { relativeUrl, searchParams } = useLocation()

  const dispatch = useDispatch()
  const isModerator = useSelector(getIsCatalogModerator)
  const uiState = useSelector(getCatalogUiState)
  const isRoleMarketingPhotos = useSelector(getIsCatalogRoleMarketingPhotos)

  const isSendToAlertsShown = isRoleMarketingPhotos || isModerator

  useEffect(() => {
    dispatch(actions.setCatalogBrand({ brand }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!catalog) return

    googleTagManagerTrack(GoogleTagManagerEvent.ViewCatalog, {
      catalog_id: catalog.id,
      catalog_code: catalog.code,
      catalog_name: catalog.title,
    })
  }, [catalog])

  useEffect(() => {
    dispatch(actions.fetchDataRequest({ relativeUrl, searchParams }))
  }, [dispatch, relativeUrl, searchParams])

  const handleErrorLogging = (feature: CatalogFeature) => (err: Error | null) => {
    logCatalogError(err, feature)
  }

  const handleRetryCta = () => {
    dispatch(actions.fetchDataRequest({ relativeUrl, searchParams }))
  }

  const renderFilters = () => {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorBoundary.ComponentError}
        preventLog
        onError={handleErrorLogging(CatalogFeature.Filters)}
      >
        <Filters learnMoreUrl={learnMoreUrl} />
        <Spacer size={Spacer.Size.Small} />
        <SelectedFilters />
        <Spacer size={Spacer.Size.Small} />
        <Divider />
        <Spacer size={Spacer.Size.XSmall} />
        <SubcatalogNavigation />
        <Divider />
        <ResultRanking learnMoreUrl={learnMoreUrl} />
      </ErrorBoundary>
    )
  }

  const renderMobileFilters = () => {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorBoundary.ComponentError}
        preventLog
        onError={handleErrorLogging(CatalogFeature.MobileFilters)}
      >
        <div className="u-flexbox">
          <div className="u-flexbox u-flex-direction-column u-margin-right-auto">
            <BrandButton />
            <Spacer />
          </div>
          <div className="u-flexbox u-align-items-flex-start">
            <MobileFilters learnMoreUrl={learnMoreUrl} />
          </div>
        </div>
        <ResultRanking learnMoreUrl={learnMoreUrl} />
      </ErrorBoundary>
    )
  }

  const renderTitleBlock = () => {
    return (
      <div className="u-flexbox">
        <div className="u-margin-right-auto">
          <div>
            <div className="u-inline-block u-ui-margin-right-regular">
              <Title />
            </div>
          </div>
          <div className="u-ui-margin-top-small">
            <BrandButton />
          </div>
        </div>
        <div className="u-tablets-up-only u-flexbox u-align-items-flex-end">
          <SearchSubscribeButton />
        </div>
      </div>
    )
  }

  const renderHeader = () => {
    const headerBlock = (
      <nav>
        <Breadcrumbs truncate />
      </nav>
    )

    if (breakpoints.phones) {
      return (
        <>
          {headerBlock}
          <Title truncate />
          <Spacer />
          {renderMobileFilters()}
        </>
      )
    }

    return (
      <>
        {headerBlock}
        {renderTitleBlock()}
        <Spacer size={Spacer.Size.Medium} />
        <Divider />
        <Spacer size={Spacer.Size.Medium} />
        {renderFilters()}
      </>
    )
  }

  const renderCatalog = () => {
    if (uiState === UiState.Pending) {
      return (
        <div className="u-flexbox u-justify-content-center">
          <Loader testId="catalog-page-loader" />
        </div>
      )
    }

    if (uiState === UiState.Failure) {
      return (
        <EmptyState
          title={<FormattedMessage id="catalog.generic_error.title" />}
          body={<FormattedMessage id="catalog.generic_error.body" />}
          action={
            <Button
              text={<FormattedMessage id="catalog.generic_error.cta" />}
              styling={Button.Styling.Filled}
              onClick={handleRetryCta}
            />
          }
        />
      )
    }

    return (
      <>
        {renderHeader()}
        <div className="u-flexbox u-justify-content-between">
          <div className="u-flexbox">{isRoleMarketingPhotos && <Marketing />}</div>
          {isSendToAlertsShown && <SendToAlerts />}
        </div>
        <ShippingFeesAppliedBanner prefix={<Spacer size={Spacer.Size.Medium} />} />
        <Spacer size={breakpoints.phones ? Spacer.Size.Small : Spacer.Size.Medium} />
        <ErrorBoundary
          FallbackComponent={ErrorBoundary.ComponentError}
          preventLog
          onError={handleErrorLogging(CatalogFeature.Items)}
        >
          <Items />
          <Pagination />
        </ErrorBoundary>
      </>
    )
  }

  return (
    <div className="row content-wrapper-flex">
      <section className="content-container">{renderCatalog()}</section>
      <aside id="sidebar" className="sidebar-container">
        <Advertisement shape={AdShape.Rectangle} id="ad-rectangle" isSidebarAd />
        <Advertisement shape={AdShape.Skyscraper} id="ad-skyscraper" isSidebarAd />
      </aside>
    </div>
  )
}

const CatalogWithErrorBoundary = (props: Props) => (
  <ErrorBoundary
    FallbackComponent={ErrorBoundary.ComponentError}
    preventLog
    onError={logCatalogError}
  >
    <Catalog {...props} />
  </ErrorBoundary>
)

export default CatalogWithErrorBoundary
