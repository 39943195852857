export enum ReportReasonCatalogSystem {
  ItemIllegal = 'ITEM-ILLEGAL-CATALOG-SYSTEM',
  ItemSexual = 'ITEM-SEXUAL-CATALOG-SYSTEM',
  ItemHygiene = 'ITEM-HYGIENE-CATALOG-SYSTEM',
  ItemRestricted = 'ITEM-RESTRICTED-CATALOG-SYSTEM',
  ItemHealthRisk = 'ITEM-HEALTH-RISK-CATALOG-SYSTEM',
  ItemScam = 'ITEM-SCAM-CATALOG-SYSTEM',
  ItemObviousCounterfeits = 'ITEM-OBVIOUS-COUNTERFEITS-CATALOG-SYSTEM',
  ItemCounterfeits = 'ITEM-COUNTERFEITS-CATALOG-SYSTEM',
}
