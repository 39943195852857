import { logError } from 'libs/utils/window'

import { CatalogFeature } from '../constants'

const LOG_PREFIX = 'Catalog'

export const logCatalogError = (error: Error | null, feature?: CatalogFeature) => {
  if (!(error instanceof Error)) return

  const loggedFeature = feature ? `${LOG_PREFIX}_${feature}` : LOG_PREFIX

  logError(error, { feature: loggedFeature })
}
