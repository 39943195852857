'use client'

import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { injectIntl } from 'react-intl'

import BreadcrumbsComponent from 'components/Breadcrumbs'
import { ROOT_URL } from 'constants/routes'
import { CatalogAttribute } from 'constants/catalog'

import { getCatalogsForBreadcrumbs } from 'state/catalog-filters/selectors'
import { State as AppState } from 'state/types'
import { withTracking, EventTrackerProps } from 'containers/TrackingProvider'
import { selectCatalogEvent } from 'libs/common/event-tracker/events'
import { IntlProps } from 'types/intl'

const mapStateToProps = (state: AppState) => ({
  catalogs: getCatalogsForBreadcrumbs(state),
})

const connector = connect(mapStateToProps)

type OwnProps = {
  showRootBreadcrumb?: boolean
  truncate?: boolean
}

type Props = OwnProps & ConnectedProps<typeof connector> & EventTrackerProps & IntlProps

class Breadcrumbs extends Component<Props> {
  static defaultProps = {
    truncate: false,
    showRootBreadcrumb: false,
  }

  renderCatalogBreadcrumbs() {
    const { catalogs } = this.props

    return catalogs.map(catalog => (
      <BreadcrumbsComponent.Item
        key={`catalog-${catalog.id}`}
        title={catalog.title}
        url={catalog.url}
        onClick={this.trackCatalogBreadcrumbClick(catalog.id)}
      />
    ))
  }

  trackCatalogBreadcrumbClick = (catalogId: number) => () => {
    this.props.track(
      selectCatalogEvent({ catalogIds: [catalogId], attributeId: CatalogAttribute.Breadcrumb }),
    )
  }

  render() {
    const { catalogs, truncate, showRootBreadcrumb } = this.props

    if (!catalogs.length) return null

    return (
      <BreadcrumbsComponent truncate={truncate}>
        {showRootBreadcrumb && (
          <BreadcrumbsComponent.Item
            key="home"
            title={this.props.intl.formatMessage({ id: 'breadcrumbs.home' })}
            url={ROOT_URL}
          />
        )}

        {this.renderCatalogBreadcrumbs()}
      </BreadcrumbsComponent>
    )
  }
}

const ComposedBreadcrumbs = withTracking(injectIntl(Breadcrumbs))

export { ComposedBreadcrumbs as Breadcrumbs }
export default connector(ComposedBreadcrumbs)
