import { OptionType } from 'constants/dynamic-filters'
import { SelectableListItem } from 'types/components'
import { DynamicFilterOptionModel } from 'types/models/dynamic-filter'
import { FilterCatalogModelWithFilterGroups } from 'types/models/filter-catalog'
import { FilterGroupModel } from 'types/models/filter-group'

import { modifySelectedNavigationalFilters } from './utils'

export const transformDynamicFilterDefaultOptions = (
  options: Array<DynamicFilterOptionModel>,
  optionType: OptionType,
  isHighlighted?: boolean,
  selectedIds?: Array<number>,
): Array<SelectableListItem<DynamicFilterOptionModel>> => {
  const list =
    optionType === OptionType.Default
      ? options.map(option => ({
          id: option.id,
          title: option.title,
          value: option.id,
          data: option,
        }))
      : []

  if (isHighlighted && selectedIds?.length) {
    const selectedItems = list.filter(item => selectedIds.includes(item.id))
    const remainingItems = list.filter(item => !selectedIds.includes(item.id))

    return [...selectedItems, ...remainingItems]
  }

  return list
}

export const transformDynamicFilterNavigationalOptions = (
  options: Array<DynamicFilterOptionModel>,
  optionType: OptionType,
  isHighlighted?: boolean,
  selectedIds?: Array<number>,
): Array<FilterCatalogModelWithFilterGroups<DynamicFilterOptionModel>> => {
  const navigationalGroups =
    optionType === OptionType.Navigational
      ? options.map(({ id, type, title, options: navOptions }) => ({
          code: `${type}_${id}`,
          title,
          matchingGroups: navOptions.map(
            ({ id: matchingId, title: matchingTitle, options: matchingOptions }) => ({
              id: matchingId,
              description: matchingTitle,
              items: matchingOptions.map(matchingOption => ({
                id: matchingOption.id,
                title: matchingOption.title,
                data: matchingOption,
              })),
            }),
          ),
        }))
      : []

  if (isHighlighted && selectedIds?.length) {
    const modifiedNavigationalGroups =
      optionType === OptionType.Navigational
        ? options.map(({ id, type, title, options: navOptions }) => ({
            code: `${type}_${id}`,
            title,
            matchingGroups: modifySelectedNavigationalFilters(
              navOptions.map(
                ({ id: matchingId, title: matchingTitle, options: matchingOptions }) => ({
                  id: matchingId,
                  description: matchingTitle,
                  items: matchingOptions.map(matchingOption => ({
                    id: matchingOption.id,
                    title: matchingOption.title,
                    data: matchingOption,
                  })),
                }),
              ),
              selectedIds,
            ),
          }))
        : []

    return modifiedNavigationalGroups
  }

  return navigationalGroups
}

export const transformDynamicFilterGroupOptions = (
  options: Array<DynamicFilterOptionModel>,
  optionType: OptionType,
  isHighlighted?: boolean,
  selectedIds?: Array<number>,
): Array<FilterGroupModel<DynamicFilterOptionModel>> => {
  const groups =
    optionType === OptionType.Group
      ? options.map(({ id, title, options: groupOptions }) => ({
          id,
          description: title,
          items: groupOptions.map(groupOption => ({
            id: groupOption.id,
            title: groupOption.title,
            data: groupOption,
          })),
        }))
      : []

  if (isHighlighted && selectedIds?.length) {
    return modifySelectedNavigationalFilters(groups, selectedIds)
  }

  return groups
}
