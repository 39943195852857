export enum CatalogFeature {
  MobileFilters = 'mobile_filters',
  Filters = 'filters',
  Items = 'items',
}

export const catalogInsertPositions = {
  closetPromo: {
    wide: {
      first: 8,
      distance: 24,
    },
    desktops: {
      first: 6,
      distance: 24,
    },
    tablets: {
      first: 6,
      distance: 24,
    },
    phones: {
      first: 6,
      distance: 24,
    },
  },
  activationBanner: {
    wide: {
      first: 8,
      distance: 20,
    },
    desktops: {
      first: 6,
      distance: 18,
    },
    tablets: {
      first: 6,
      distance: 18,
    },
    phones: {
      first: 6,
      distance: 18,
    },
  },
  ads: {
    wide: {
      first: 20,
      distance: 24,
    },
    phones: {
      first: 18,
      distance: 24,
    },
  },
}
