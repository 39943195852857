import { ChangeEvent, useState, useEffect, useCallback, useRef } from 'react'
import { noop } from 'lodash'

import { formatCurrency, formatAmount } from 'libs/utils/formatString'

type RenderProps = {
  value: string | null | undefined
  handleValue: (event: ChangeEvent<HTMLInputElement>) => void
  handleFocus: () => void
  handleBlur: () => void
}

type Props = {
  children: (props: RenderProps) => JSX.Element
  onChange?: (value: string | null) => void
  value?: string | null
  maxLength?: number
  locale: string
  currency: string
  forceUpdateValue?: boolean
}

const CurrencyMask = ({
  value,
  locale,
  currency,
  children,
  maxLength,
  onChange = noop,
  forceUpdateValue = false,
}: Props) => {
  const isFocused = useRef(false)

  const formatValue = useCallback(
    (valueToFormat?: string | null) => {
      if (!valueToFormat) return null
      if (!locale || !currency) return valueToFormat
      if (isFocused.current) {
        return formatAmount(valueToFormat, maxLength ? { maxLength } : undefined)
      }

      return formatCurrency(valueToFormat, { locale, currency })
    },
    [locale, currency, isFocused, maxLength],
  )

  const [inputValue, setInputValue] = useState<string | null>(formatValue(value))

  useEffect(() => {
    if (!forceUpdateValue) return

    setInputValue(formatValue(value))
  }, [formatValue, value, forceUpdateValue])

  const handleValue = (event: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatValue(event.target?.value)

    setInputValue(formattedValue)
    onChange(formattedValue)
  }

  const handleFocusChange = () => {
    setInputValue(formatValue(inputValue))
  }

  const handleFocus = () => {
    isFocused.current = true
    handleFocusChange()
  }

  const handleBlur = () => {
    isFocused.current = false
    handleFocusChange()
  }

  return children({
    value: inputValue || '',
    handleValue,
    handleFocus,
    handleBlur,
  })
}

export default CurrencyMask
