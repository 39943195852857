import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { toggleBrandFollow } from 'data/api'
import { getIsAuthenticated } from 'state/session/selectors'
import { actions } from 'state/auth/slice'
import { followBrandEvent } from 'libs/common/event-tracker/events'

import useTracking from './useTracking'

type Props = {
  brandId: number
  isFollowed: boolean
}

const useFollowBrand = ({ brandId, isFollowed: initialIsFollowed }: Props) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(getIsAuthenticated) || false
  const [isFollowed, setIsFollowed] = useState(initialIsFollowed)
  const { track } = useTracking()
  const isLoading = useRef(false)

  useEffect(() => {
    setIsFollowed(initialIsFollowed)
  }, [initialIsFollowed])

  function signIn() {
    dispatch(actions.openAuthModal())
  }

  async function toggle() {
    if (isLoading.current) return

    isLoading.current = true
    const response = await toggleBrandFollow({ brandId })
    isLoading.current = false

    if ('errors' in response) return

    const isFollowEvent = response.is_followed

    track(followBrandEvent({ brandId, isFollowEvent }))
    setIsFollowed(isFollowEvent)
  }

  return {
    isFollowed,
    toggleFollow: isLoggedIn ? toggle : signIn,
  }
}

export default useFollowBrand
