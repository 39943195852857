'use client'

import { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { Cell, Divider, Text } from '@vinted/web-ui'

import FilterDropdown from 'components/FilterDropdown'
import SelectableItemList from 'components/SelectableItemList'
import { SortByOption } from 'constants/filter'

import { getSortBy } from 'state/catalog-filters/selectors'
import { actions } from 'state/catalog-filters/slice'
import { getCatalogItemErrors } from 'state/items/selectors'
import { State as AppState } from 'state/types'

import { FormattedMessageValues, SelectableListItem } from 'types/components'

const mapStateToProps = (state: AppState) => ({
  selected: getSortBy(state),
  errors: getCatalogItemErrors(state),
})

const mapActionsToProps = {
  onItemClick: actions.toggleSortFilterOption,
}

const connector = connect(mapStateToProps, mapActionsToProps)

type SortItem = SelectableListItem<SortByOption, SortByOption>

type OwnProps = {
  learnMoreUrl: string | null | undefined
  useDropdownBottomEndPlacement: boolean
}

type Props = ConnectedProps<typeof connector> & OwnProps

class SortFilter extends Component<Props> {
  static defaultProps = {
    selected: SortByOption.Relevance,
    useDropdownBottomEndPlacement: false,
  }

  // TODO: convert into functional component
  // eslint-disable-next-line class-methods-use-this
  translate = (id: string, values?: FormattedMessageValues) => (
    <FormattedMessage id={`catalog.filters.sort.${id}`} values={values} />
  )

  handleItemClick = (item: SortItem) => {
    const { onItemClick } = this.props

    onItemClick({ sortBy: item.value })
  }

  renderDropdown() {
    const items = Object.values(SortByOption).map(option => ({
      id: option,
      title: this.translate(`options.${option}`),
      value: option,
    }))

    const { selected } = this.props

    return (
      <div className="u-ui-padding-vertical-x-small" data-testid="sort-by-dropdown">
        <SelectableItemList<SortByOption, SortByOption>
          name="sort_by"
          isMultiSelect={false}
          items={items}
          selected={[selected || SortByOption.Relevance]}
          onItemClick={this.handleItemClick}
          itemButtonTestId="sort-by-list"
          disableButtonClicks
        />
        {this.renderLearnMoreMessage()}
      </div>
    )
  }

  renderLearnMoreMessage() {
    const { learnMoreUrl } = this.props

    if (!learnMoreUrl) return null

    const action = <a href={learnMoreUrl}>{this.translate('learn_more.action')}</a>

    return (
      <>
        <Divider />
        <Cell>
          <Text
            type={Text.Type.Caption}
            text={this.translate('learn_more.message', { action })}
            as="span"
          />
        </Cell>
      </>
    )
  }

  render() {
    const { errors, selected, useDropdownBottomEndPlacement } = this.props

    if (errors) return null

    return (
      <div className="u-ui-margin-right-regular u-ui-margin-bottom-regular">
        <FilterDropdown
          title={this.translate('name')}
          placement={useDropdownBottomEndPlacement ? 'bottom-end' : undefined}
          areAnyOfItemsSelected={!!selected && selected !== SortByOption.Relevance}
          testId="catalog--sort-filter"
          closeOnClick
          useChipAsTrigger
        >
          {this.renderDropdown()}
        </FilterDropdown>
      </div>
    )
  }
}

export { SortFilter }
export default connector(SortFilter)
