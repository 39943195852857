import { CatalogPhotoThumbnail } from 'constants/catalog'
import { CatalogPhotoDto } from 'types/dtos/catalog'

export const getCatalogPhotoSrc = (photo: CatalogPhotoDto) => {
  const targetSrc = photo.thumbnails.find(({ type }) => type === CatalogPhotoThumbnail.Thumb24)
  const retinaSrc = photo.thumbnails.find(({ type }) => type === CatalogPhotoThumbnail.Thumb48)
  const src = targetSrc?.url || photo.url

  if (!targetSrc || !retinaSrc) return { src }

  return {
    src,
    srcset: `${src} 1x, ${retinaSrc.url} 2x`,
  }
}
