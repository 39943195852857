'use client'

import { useSelector } from 'react-redux'
import { Text } from '@vinted/web-ui'

import { FormattedMessage } from 'components/@support'
import { getCatalogItemErrors, getCatalogItemTotalEntries } from 'state/items/selectors'

export const MAX_ITEM_COUNT = 500

const ItemCount = () => {
  const totalEntries = useSelector(getCatalogItemTotalEntries)
  const errors = useSelector(getCatalogItemErrors)

  if (!totalEntries) return null
  if (errors) return null

  const count = totalEntries > MAX_ITEM_COUNT ? `${MAX_ITEM_COUNT}+` : totalEntries

  return (
    <Text
      type={Text.Type.Subtitle}
      text={
        <FormattedMessage
          id="catalog.item_count"
          pluralize
          count={totalEntries}
          values={{ count }}
        />
      }
      as="span"
    />
  )
}

export default ItemCount
