'use client'

import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useLocation from 'hooks/useLocation'
import useTracking from 'hooks/useTracking'
import { CatalogAttribute } from 'constants/catalog'
import { selectCatalogEvent } from 'libs/common/event-tracker/events'
import { urlWithParams } from 'libs/utils/url'
import { CatalogModel } from 'types/models'

import { getFilters, getSubcatalogs } from 'state/catalog-filters/selectors'
import { actions } from 'state/catalog-filters/slice'
import { buildSubcatalogUrlParams } from 'state/catalog-filters/utils'

const SubcatalogNavigation = () => {
  const dispatch = useDispatch()
  const { searchParams } = useLocation()
  const { track } = useTracking()

  const subCatalogs = useSelector(getSubcatalogs)
  const filters = useSelector(getFilters)

  const urlParams = buildSubcatalogUrlParams({
    extraParams: searchParams,
    filters,
  })

  const handleClick = (catalog: CatalogModel) => (event: MouseEvent) => {
    event.preventDefault()

    const catalogIds = [catalog.id]
    track(
      selectCatalogEvent({
        catalogIds,
        attributeId: CatalogAttribute.Subcatalog,
      }),
    )
    dispatch(actions.catalogFilterSet({ ids: catalogIds }))
  }

  const renderNavItem = (catalog: CatalogModel) => {
    return (
      <li className="nav-item" key={catalog.id}>
        <a
          className="nav-link u-ellipsis u-block"
          href={urlWithParams(catalog.url, urlParams)}
          onClick={handleClick(catalog)}
        >
          {catalog.title}
        </a>
      </li>
    )
  }

  return (
    <ul className="nav nav-detailed nav-page-categories" data-testid="sub-catalog-navigation-links">
      {subCatalogs.map(renderNavItem)}
    </ul>
  )
}

export default SubcatalogNavigation
