'use client'

import { Cell, Label, Radio, Spacer, Validation } from '@vinted/web-ui'

import { ReportReasonCatalogSystem } from 'constants/report-reason'

const CATALOG_SYSTEM_REPORT_REASONS = Object.values(ReportReasonCatalogSystem)

type Props = {
  selectedValue: ReportReasonCatalogSystem | undefined
  onSelect: (value: ReportReasonCatalogSystem) => void
  isSubmitAttempted: boolean
}

const ReportReasonList = ({ isSubmitAttempted, selectedValue, onSelect }: Props) => {
  const handleSelectionClick = (value: ReportReasonCatalogSystem) => () => {
    onSelect(value)
  }

  const renderValidation = () => {
    if (!isSubmitAttempted) return null
    if (selectedValue) return null

    return (
      <div className="u-flexbox">
        <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
        <Validation text="Please select reason" theme="warning" />
      </div>
    )
  }

  return (
    <>
      <Label text="Select alert reason" type={Label.Type.Stacked} />
      {renderValidation()}
      <div className="send-to-alerts__report-reasons-list">
        {CATALOG_SYSTEM_REPORT_REASONS.map(reason => (
          <Cell
            key={reason}
            title={reason}
            suffix={<Radio name={`${reason}-radio`} checked={reason === selectedValue} />}
            onClick={handleSelectionClick(reason)}
            type={Cell.Type.Navigating}
            divider
          />
        ))}
      </div>
    </>
  )
}

export default ReportReasonList
