'use client'

import { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'

import { Text } from '@vinted/web-ui'

import FilterDropdown from 'components/FilterDropdown'
import PriceRangeInput from 'components/PriceRangeInput'

import { State as AppState } from 'state/types'
import { actions } from 'state/catalog-filters/slice'
import {
  getPriceFrom,
  getPriceTo,
  getFilterCurrency,
  getCurrency,
} from 'state/catalog-filters/selectors'
import { PriceRange } from 'state/catalog-filters/types'
import { CurrencyAmountModel } from 'types/models'
import { formatCurrency } from 'libs/utils/formatString'
import { getLocale } from 'state/intl/selectors'

const mapStateToProps = (state: AppState) => ({
  priceFrom: getPriceFrom(state),
  priceTo: getPriceTo(state),
  locale: getLocale(state),
  currency: getFilterCurrency(state) || getCurrency(state),
})

const mapActionsToProps = {
  setPriceRange: actions.priceRangeFilterSet,
}

const connector = connect(mapStateToProps, mapActionsToProps)

type OwnProps = {
  minimumValidation?: CurrencyAmountModel
}

type Props = ConnectedProps<typeof connector> & OwnProps

type State = {
  shouldUpdatePrice: boolean
  isPriceRangeUpToDate: boolean
}

class PriceFilter extends Component<Props> {
  state: Readonly<State> = {
    shouldUpdatePrice: false,
    isPriceRangeUpToDate: true,
  }

  range: PriceRange = {
    priceFrom: this.props.priceFrom,
    priceTo: this.props.priceTo,
  }

  handlePriceRangeChange = (priceRange: PriceRange, currency: string) => {
    const { setPriceRange } = this.props

    this.range = priceRange
    setPriceRange({ priceRange, currency })
  }

  handleDropdownOpen = () => {
    const { priceFrom, priceTo } = this.props

    this.range = { priceFrom, priceTo }
    this.setState({ shouldUpdatePrice: true }, () => {
      this.setState({ isPriceRangeUpToDate: true })
    })
  }

  handleDropdownClose = () => {
    this.setState({ shouldUpdatePrice: false, isPriceRangeUpToDate: false })
  }

  // TODO: convert into functional component
  // eslint-disable-next-line class-methods-use-this
  hasPriceRangeChanged = (
    prevValidation?: CurrencyAmountModel,
    newValidation?: CurrencyAmountModel,
  ) => {
    return prevValidation?.amount !== newValidation?.amount
  }

  componentDidUpdate(prevProps: Props) {
    const { minimumValidation, currency, priceFrom, priceTo } = this.props

    if (prevProps.minimumValidation !== minimumValidation) {
      if (minimumValidation?.amount) {
        if (this.hasPriceRangeChanged(prevProps.minimumValidation, minimumValidation)) {
          const newPriceFrom =
            priceFrom && parseFloat(priceFrom) < parseFloat(minimumValidation.amount)
              ? null
              : priceFrom

          const newPriceTo =
            priceTo && parseFloat(priceTo) < parseFloat(minimumValidation.amount) ? null : priceTo

          const priceRange = {
            priceFrom: newPriceFrom,
            priceTo: newPriceTo,
          }
          this.handlePriceRangeChange(priceRange, currency)
        }
      }
    }
  }

  renderDropdown() {
    const { shouldUpdatePrice, isPriceRangeUpToDate } = this.state
    const { minimumValidation, currency, locale } = this.props

    return (
      <div className="u-ui-padding-vertical-x-small">
        <div className="u-flexbox">
          <PriceRangeInput
            priceRange={this.range}
            priceFromTitle={<FormattedMessage id="catalog.filters.price.price_from" />}
            priceToTitle={<FormattedMessage id="catalog.filters.price.price_to" />}
            onPriceRangeChange={this.handlePriceRangeChange}
            shouldUpdateInputValues={shouldUpdatePrice}
            shouldFocusInput={isPriceRangeUpToDate}
            minimumValidation={minimumValidation}
          />
        </div>
        {minimumValidation && (
          <div className="u-ui-padding-horizontal-medium u-ui-padding-bottom-regular">
            <Text
              type={Text.Type.Caption}
              theme="muted"
              as="span"
              text={
                <FormattedMessage
                  id="catalog.filters.price.designer_price_message"
                  values={{ value: formatCurrency(minimumValidation.amount, { locale, currency }) }}
                />
              }
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    const { priceTo, priceFrom } = this.props

    return (
      <div className="u-ui-margin-right-regular u-ui-margin-bottom-regular">
        <FilterDropdown
          title={<FormattedMessage id="catalog.filters.price.name" />}
          areAnyOfItemsSelected={!!(priceTo || priceFrom)}
          onOpen={this.handleDropdownOpen}
          onClose={this.handleDropdownClose}
          testId="catalog--price-filter"
          useChipAsTrigger
        >
          {this.renderDropdown()}
        </FilterDropdown>
      </div>
    )
  }
}

export { PriceFilter }
export default connector(PriceFilter)
