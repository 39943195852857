'use client'

import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Pagination as PaginationComponent } from '@vinted/web-ui'

import useBreakpoint from 'hooks/useBreakpoint'
import useLocation from 'hooks/useLocation'

import {
  getCatalogItemCurrentPage,
  getCatalogItemTotalPages,
  getCatalogItemPerPage,
  getCatalogItemTime,
  getCatalogItemErrors,
} from 'state/items/selectors'
import { actions } from 'state/items/slice'
import { buildCatalogPaginationParams } from 'state/catalog-filters/utils'

import { CATALOG_URL } from 'constants/routes'
import { toUrlQuery } from 'libs/utils/url'
import { filterEmptyValues } from 'libs/utils/object'

const Pagination = () => {
  const breakpoints = useBreakpoint()
  const dispatch = useDispatch()
  const { searchParams } = useLocation()

  const currentPage = useSelector(getCatalogItemCurrentPage)
  const totalPages = useSelector(getCatalogItemTotalPages)
  const perPage = useSelector(getCatalogItemPerPage)
  const time = useSelector(getCatalogItemTime)
  const errors = useSelector(getCatalogItemErrors)

  const handlePageSelect = (page: number, event: MouseEvent) => {
    event.preventDefault()

    if (currentPage !== page) {
      dispatch(actions.setCatalogItemsPage({ page }))
    }
  }

  const urlBuilder = (page: number, perPageOption?: number) => {
    const urlParams = {
      ...searchParams,
      ...buildCatalogPaginationParams({
        page,
        perPage: perPageOption || perPage,
        time,
      }),
    }

    return `${CATALOG_URL}?${toUrlQuery(filterEmptyValues(urlParams))}`
  }

  if (errors || !totalPages || !perPage || totalPages <= 1) return null

  let paginationStyling: ComponentProps<typeof PaginationComponent>['styling'] | undefined

  if (breakpoints.phones) paginationStyling = PaginationComponent.Styling.Narrow

  return (
    <Card>
      <PaginationComponent
        styling={paginationStyling}
        currentPage={currentPage}
        pageCount={totalPages}
        onPageClick={handlePageSelect}
        onNextClick={handlePageSelect}
        onPrevClick={handlePageSelect}
        urlBuilder={urlBuilder}
        testId="catalog-pagination"
      />
    </Card>
  )
}

export default Pagination
