'use client'

import { Button } from '@vinted/web-ui'

import { ADMIN_CATALOG_ITEM_URL } from 'constants/routes'
import useLocation from 'hooks/useLocation'
import { toUrlQuery } from 'libs/utils/url'

const Marketing = () => {
  const { searchParams } = useLocation()

  const query = toUrlQuery({
    ...searchParams,
    marketing_use_allowed: 1,
    view: 'grid',
  })
  const url = `${ADMIN_CATALOG_ITEM_URL}/?${query}`

  return (
    <Button size={Button.Size.Small} inline url={url}>
      Show marketing use allowed items
    </Button>
  )
}

export default Marketing
