'use client'

import { Cell, Image, Spacer, Text } from '@vinted/web-ui'
import { InView } from 'react-intersection-observer'

import { DynamicFilterOptionModel, OptionExtension } from 'types/models/dynamic-filter'
import { OptionExtentionType, PrefixImageSize } from 'constants/dynamic-filters'
import { SelectableListItem } from 'types/components'

import ColorBubble from 'components/ColorBubble/ColorBubble'

type Props = {
  type: string
  item: SelectableListItem<DynamicFilterOptionModel, string | number>
  itemElementProps?: ComponentProps<typeof Cell>
  handleItemView: (inView: boolean, entry: IntersectionObserverEntry) => void
}

const DynamicFilterCell = ({ type, item, itemElementProps, handleItemView }: Props) => {
  const itemsCount = Number(item.data?.itemsCount)
  const itemsCountMax = Number(item.data?.itemsCountMax)

  const getPrefixSize = (size?: string) => {
    switch (size) {
      case PrefixImageSize.Medium: {
        return Image.Size.Medium
      }
      case PrefixImageSize.Regular: {
        return Image.Size.Regular
      }
      default: {
        return Image.Size.Regular
      }
    }
  }

  const renderPrefix = (id: number | string, prefix?: OptionExtension) => {
    if (!prefix) return null

    switch (prefix.type) {
      case OptionExtentionType.Color: {
        return prefix.code ? (
          <ColorBubble code={prefix.code} testId={`color_code_${prefix.code}`} />
        ) : null
      }
      case OptionExtentionType.Image: {
        return prefix.url ? (
          <Image
            src={prefix.url}
            scaling={Image.Scaling.Cover}
            size={getPrefixSize(prefix.size)}
            testId={`image_id_${id}`}
          />
        ) : null
      }
      default:
        return null
    }
  }

  return (
    <InView onChange={handleItemView}>
      <Cell
        {...itemElementProps}
        title={
          <>
            <Text text={item.title} type={Text.Type.Title} as="span" />
            {itemsCount > 0 && itemsCountMax && (
              <>
                <Spacer orientation={Spacer.Orientation.Vertical} />
                <Text
                  text={itemsCount >= itemsCountMax ? `(${itemsCountMax}+)` : `(${itemsCount})`}
                  type={Text.Type.Subtitle}
                  as="span"
                />
              </>
            )}
          </>
        }
        body={item.data?.description ? item.data?.description : null}
        prefix={renderPrefix(item.id, item.data?.prefix)}
        testId={`selectable-item-${type}-${item.id}`}
      />
    </InView>
  )
}

export default DynamicFilterCell
