'use client'

import { useRef, useMemo, useState, ChangeEvent, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import uuid from 'uuid'
import { Divider, EmptyState, InputText } from '@vinted/web-ui'

import { fetchDynamicFilterSearchRequest } from 'state/catalog-filters/actions'
import { actions } from 'state/catalog-filters/slice'
import { flattenDynamicFilterOptions } from 'state/catalog-filters/utils'
import { transformDynamicFilterDefaultOptions } from 'state/catalog-filters/transformers'
import useTracking from 'hooks/useTracking'
import ScrollableArea from 'components/ScrollableArea'
import FilterDropdown from 'components/FilterDropdown'
import SelectableItemList from 'components/SelectableItemList'
import { OptionType, SelectionType } from 'constants/dynamic-filters'
import {
  DynamicFilterOptionModel,
  ExperimentModel,
  SearchTranslationsModel,
} from 'types/models/dynamic-filter'
import { SelectableListItem } from 'types/components'
import { getGlobalSearchSessionId, getSearchSessionId } from 'state/catalog-filters/selectors'
import { getExposee } from 'state/session/selectors'
import { RenderItemProps } from 'components/SelectableItemList/SelectableItemList'
import DynamicFilterCell from 'components/DynamicFilters/common/DynamicFilterCell'
import {
  abTestExposeEvent,
  userClickDynamicFilterOptionEvent,
  userViewDynamicFilterOptionEvent,
} from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { SELECT_DROPDOWN_MAX_HEIGHT, SELECT_DROPDOWN_MIN_WIDTH } from 'constants/filter'

type ListOptionItem = SelectableListItem<DynamicFilterOptionModel>

type Props = {
  type: string
  title: string
  selectionType: SelectionType
  isSelectionHighlighted?: boolean
  isHidden?: boolean
  experiment?: ExperimentModel
  rootOptionType: OptionType
  options: Array<DynamicFilterOptionModel>
  selectedIds: Array<number>
  fromScreen: Screen
  translations?: SearchTranslationsModel
  onListOptionSelect: (id: number) => void
}

const SearchFilterDropdown = ({
  type,
  title,
  selectionType,
  isSelectionHighlighted,
  isHidden,
  experiment,
  rootOptionType,
  options,
  selectedIds,
  fromScreen,
  translations,
  onListOptionSelect,
}: Props) => {
  const { track } = useTracking()
  const dispatch = useDispatch()
  const exposee = useSelector(getExposee)
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const searchSessionId = useSelector(getSearchSessionId)
  const seenItems = useRef<Array<number>>([])
  const [query, setQuery] = useState<string | null>(null)
  const [highlightedSelectedIds, setHighlightedSelectedIds] = useState<Array<number>>(selectedIds)
  const filteringSessionId = useMemo(() => uuid.v4(), [])

  useEffect(() => {
    if (experiment) {
      track(abTestExposeEvent({ ...exposee, ...experiment }))
    }
  }, [experiment, exposee, track])

  const flattedOptions = useMemo(() => {
    return flattenDynamicFilterOptions(options)
  }, [options])

  const listItems = useMemo(() => {
    return transformDynamicFilterDefaultOptions(
      options,
      rootOptionType,
      isSelectionHighlighted,
      highlightedSelectedIds,
    )
  }, [options, rootOptionType, isSelectionHighlighted, highlightedSelectedIds])

  const getCurrentPosition = (id: number) =>
    flattedOptions.findIndex(option => option.id === id) + 1

  const handleItemClick = (item: ListOptionItem) => {
    const id = Number(item.id)
    const position = getCurrentPosition(id)
    const itemsCount = item.data?.itemsCount

    onListOptionSelect(id)

    track(
      userClickDynamicFilterOptionEvent({
        id,
        type,
        code: type,
        position,
        isSelected: !selectedIds.includes(id),
        itemsCount,
        searchText: query || '',
        filteringSessionId,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )
  }

  const getHandleItemView = (item: ListOptionItem) => (inView: boolean) => {
    if (!inView) return

    const id = Number(item.id)
    if (seenItems.current.includes(id)) return

    const position = getCurrentPosition(id)

    track(
      userViewDynamicFilterOptionEvent({
        id,
        type,
        code: type,
        position,
        itemsCount: item.data?.itemsCount,
        searchText: query || '',
        filteringSessionId,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )

    seenItems.current = [...seenItems.current, id]
  }

  const handleOpenDropdown = () => {
    seenItems.current = []
  }

  const handleCloseDropdown = () => {
    setHighlightedSelectedIds(selectedIds)

    if (query) setQuery('')
  }

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.currentTarget.value
    setQuery(searchQuery)

    if (searchQuery) {
      dispatch(actions.setFilterSearchQuery({ text: searchQuery, code: type }))
      dispatch(fetchDynamicFilterSearchRequest(type, searchQuery))
    }

    setHighlightedSelectedIds(selectedIds)
  }

  useEffect(() => {
    if (query !== '') return

    // clear up the query and search result if user clear the search text
    dispatch(fetchDynamicFilterSearchRequest(type, ''))
    dispatch(actions.setFilterSearchQuery({ text: null, code: null }))
  }, [query, type, dispatch])

  const renderItemCell = ({
    item,
    itemElementProps,
  }: RenderItemProps<DynamicFilterOptionModel>) => (
    <DynamicFilterCell
      key={item.id}
      type={type}
      item={item}
      itemElementProps={itemElementProps}
      handleItemView={getHandleItemView(item)}
    />
  )

  const renderEmptyState = () => (
    <EmptyState title={translations?.noResults?.title} body={translations?.noResults?.body} />
  )

  const renderDropdown = () => {
    return (
      <div className="u-ui-padding-vertical-x-small">
        <InputText
          value={query}
          name={`${type}_filter_search`}
          placeholder={translations?.placeholder}
          onChange={handleSearchInput}
        />

        <ScrollableArea maxHeight={SELECT_DROPDOWN_MAX_HEIGHT}>
          {listItems.length ? (
            <>
              <SelectableItemList
                name={`${type}_ids`}
                isMultiSelect={selectionType === SelectionType.Multi}
                items={listItems}
                selected={selectedIds}
                renderItem={renderItemCell}
                onItemClick={handleItemClick}
                disableButtonClicks
              />
              <Divider />
            </>
          ) : (
            renderEmptyState()
          )}
        </ScrollableArea>
      </div>
    )
  }

  if (isHidden) return null

  return (
    <div className="u-ui-margin-right-regular u-ui-margin-bottom-regular">
      <FilterDropdown
        title={title}
        areAnyOfItemsSelected={!!selectedIds.length}
        testId={`catalog--${type}-filter`}
        minWidth={SELECT_DROPDOWN_MIN_WIDTH}
        onOpen={handleOpenDropdown}
        onClose={handleCloseDropdown}
        useChipAsTrigger
      >
        <div className="u-ui-padding-vertical-x-small">{renderDropdown()}</div>
      </FilterDropdown>
    </div>
  )
}

export default SearchFilterDropdown
