'use client'

import { ReactNode } from 'react'
import { Modal as VintedModal, Button, Divider } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

type Props = {
  isOpen: boolean
  children?: ReactNode
  hideSubmitButton?: boolean
  testId?: string
  renderNavigation: () => ReactNode
  onSubmit?: () => void
}

const FilterModal = ({
  isOpen,
  children,
  hideSubmitButton = false,
  testId,
  renderNavigation,
  onSubmit,
}: Props) => {
  const translate = useTranslate('catalog.filters.mobile')

  return (
    <VintedModal
      show={isOpen}
      className="u-fill-width u-fill-height u-flexbox u-flex-direction-column"
      isFramed={false}
      testId={testId}
    >
      <div className="u-flex-shrink-none">
        {renderNavigation()}
        <Divider />
      </div>
      <div className="u-overflow-auto u-fill-height">{children}</div>
      {!hideSubmitButton && (
        <div className="u-ui-padding-large u-margin-top-auto">
          <Button
            text={translate('filters_screen.actions.show_results')}
            onClick={onSubmit}
            theme="primary"
            styling={Button.Styling.Filled}
          />
        </div>
      )}
    </VintedModal>
  )
}

export default FilterModal
