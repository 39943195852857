export enum Filter {
  Sort = 'sort',
  Category = 'category',
  Size = 'size',
  Brand = 'brand',
  Status = 'condition',
  Color = 'colour',
  Price = 'price',
  Country = 'country',
  Location = 'location',
  Material = 'material',
  VideoGameRating = 'video_game_rating',
  Dynamic = 'dynamic',
}
