'use client'

import { useState, useRef, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Badge, Divider, Spacer, Text, Icon } from '@vinted/web-ui'
import { ArrowLeft24, ChevronRight24 } from '@vinted/monochrome-icons'
import { compact } from 'lodash'
import uuid from 'uuid'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { OptionType, SelectionType } from 'constants/dynamic-filters'
import { DynamicFilterOptionModel } from 'types/models/dynamic-filter'

import { getGlobalSearchSessionId, getSearchSessionId } from 'state/catalog-filters/selectors'

import FilterCell from 'components/FilterCell'
import FilterModal from 'components/FilterModal'
import FilterModalNavigation from 'components/FilterModalNavigation'
import SelectableItemList from 'components/SelectableItemList'
import { SelectableListItem } from 'types/components'
import { RenderItemProps } from 'components/SelectableItemList/SelectableItemList'
import DynamicFilterCell from 'components/DynamicFilters/common/DynamicFilterCell'
import {
  userClickDynamicFilterOptionEvent,
  userViewDynamicFilterOptionEvent,
  clickEvent,
} from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import {
  transformDynamicFilterDefaultOptions,
  transformDynamicFilterGroupOptions,
  transformDynamicFilterNavigationalOptions,
} from 'state/catalog-filters/transformers'
import FilterGroupList from 'components/FilterGroupList/FilterGroupList'
import FilterCatalogList from 'components/FilterCatalogList/FilterCatalogList'
import { flattenDynamicFilterOptions } from 'state/catalog-filters/utils'
import { FilterCatalogModel } from 'types/models/filter-catalog'
import { FilterGroupModel } from 'types/models/filter-group'
import FilterCellSuffix from 'components/FilterCell/FilterCellSuffix'

type ListOptionItem = SelectableListItem<DynamicFilterOptionModel>

type Props = {
  type: string
  title: string
  selectionType: SelectionType
  isSelectionHighlighted?: boolean
  isNewFilter?: boolean
  options: Array<DynamicFilterOptionModel>
  selectedIds: Array<number>
  fromScreen: Screen
  isFilterCellVisible: boolean
  rootOptionType: OptionType
  isHidden?: boolean
  onListOptionSelect: (id: number) => void
  clearSelectedOptions: (type: string, ids?: Array<number>) => void
  onSubmit: () => void
  onFilterCellClick: () => void
  onFilterCellView: () => void
  onModalOpen: () => void
  onModalClose: () => void
}

const ListFilterModal = ({
  type,
  title,
  selectionType,
  isSelectionHighlighted,
  isNewFilter,
  options,
  selectedIds,
  fromScreen,
  isFilterCellVisible,
  rootOptionType,
  isHidden,
  onListOptionSelect,
  clearSelectedOptions,
  onSubmit,
  onFilterCellClick,
  onFilterCellView,
  onModalOpen,
  onModalClose,
}: Props) => {
  const translate = useTranslate()
  const { track } = useTracking()
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const searchSessionId = useSelector(getSearchSessionId)
  const seenItems = useRef<Array<number>>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCatalogCodes, setSelectedCatalogCodes] = useState<Array<string>>([])
  const [highlightedSelectedIds, setHighlightedSelectedIds] = useState<Array<number>>(selectedIds)
  const [filteringSessionId, setFilteringSessionId] = useState('')
  const screen = `${Screen.DynamicFilter}_${type}`

  const flattedOptions = useMemo(() => {
    return flattenDynamicFilterOptions(options)
  }, [options])

  const suffixText = useMemo(() => {
    return compact(
      selectedIds.map(selectedId => flattedOptions.find(option => option.id === selectedId)?.title),
    ).join(', ')
  }, [selectedIds, flattedOptions])

  const listItems = useMemo(() => {
    return transformDynamicFilterDefaultOptions(
      options,
      rootOptionType,
      isSelectionHighlighted,
      highlightedSelectedIds,
    )
  }, [options, rootOptionType, isSelectionHighlighted, highlightedSelectedIds])

  const navigationalItems = useMemo(() => {
    return transformDynamicFilterNavigationalOptions(
      options,
      rootOptionType,
      isSelectionHighlighted,
      highlightedSelectedIds,
    )
  }, [options, rootOptionType, isSelectionHighlighted, highlightedSelectedIds])

  const getGroupItems = (catalogCode: string) => {
    const currentCatalog = navigationalItems.find(({ code }) => code === catalogCode)
    if (currentCatalog) {
      return currentCatalog.matchingGroups
    }

    return transformDynamicFilterGroupOptions(
      options,
      rootOptionType,
      isSelectionHighlighted,
      highlightedSelectedIds,
    )
  }

  const closeModal = () => {
    setFilteringSessionId('')
    seenItems.current = []

    setIsModalOpen(false)
    onModalClose()
  }

  const openModal = () => {
    setSelectedCatalogCodes(prevCodes => [...prevCodes, OptionType.Root])
    setIsModalOpen(true)

    setHighlightedSelectedIds(selectedIds)
    onModalOpen()
  }

  const getCurrentPosition = (id: number) =>
    flattedOptions.findIndex(option => option.id === id) + 1

  const handleBackClick = (catalogCode: string) => () => {
    if (catalogCode === OptionType.Root) closeModal()

    seenItems.current = []
    setSelectedCatalogCodes(prevCodes => prevCodes.filter(prevCode => prevCode !== catalogCode))
    setHighlightedSelectedIds(selectedIds)
  }

  const handleItemClick = (id: number, position: number, itemsCount?: number) => {
    onListOptionSelect(id)

    track(
      userClickDynamicFilterOptionEvent({
        id,
        type,
        code: type,
        position,
        itemsCount,
        isSelected: !selectedIds.includes(id),
        filteringSessionId,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )
  }

  const handleDefaultItemClick = (item: ListOptionItem) => {
    const id = Number(item.id)
    const position = getCurrentPosition(id)
    const itemsCount = item.data?.itemsCount

    handleItemClick(id, position, itemsCount)
  }

  const handelGroupItemClick = (id: number) => {
    const position = getCurrentPosition(id)

    handleItemClick(id, position)
  }

  const handelNavItemClick = (catalog: FilterCatalogModel) => {
    setSelectedCatalogCodes(prevCodes => [...prevCodes, catalog.code])
  }

  const handleSubmitClick = () => {
    closeModal()
    onSubmit()

    track(
      clickEvent({
        screen,
        target: ClickableElement.ApplyFilters,
      }),
    )
  }

  const clearSelectedOptionIds = (groupIds?: Array<number>) => () => {
    clearSelectedOptions(type, groupIds)

    track(
      clickEvent({
        target: ClickableElement.ClearFilters,
        screen,
      }),
    )
  }

  const handleFilterCellClick = () => {
    openModal()
    onFilterCellClick()
  }

  const getHandleItemView = (item: ListOptionItem) => (inView: boolean) => {
    if (!inView) return

    const itemId = Number(item.id)
    if (seenItems.current.includes(itemId)) return

    const position = getCurrentPosition(itemId)

    track(
      userViewDynamicFilterOptionEvent({
        id: itemId,
        type,
        code: type,
        position,
        itemsCount: item.data?.itemsCount,
        filteringSessionId,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )

    seenItems.current = [...seenItems.current, itemId]
  }

  useEffect(() => {
    if (isModalOpen) setFilteringSessionId(uuid.v4())
  }, [isModalOpen])

  const renderTitle = () => (
    <>
      <Text text={title} type={Text.Type.Title} as="span" />
      {isNewFilter && (
        <>
          <Spacer orientation={Spacer.Orientation.Vertical} />
          <Badge theme="primary" content={translate('common.new_badge')} />
        </>
      )}
    </>
  )

  const renderModalNavigation =
    (currentTitle: string, catalogCode: string, groupIds?: Array<number>) => () => {
      return (
        <FilterModalNavigation
          title={currentTitle}
          icon={<Icon name={ArrowLeft24} testId={`${type}-navigation-prefix-icon`} />}
          prefixAriaLabel={translate('catalog.filters.a11y.actions.back')}
          suffixText={translate('catalog.filters.dynamic.clear')}
          onPrefixClick={handleBackClick(catalogCode)}
          onSuffixClick={clearSelectedOptionIds(groupIds)}
        />
      )
    }

  const renderCatalogCellSuffixIcon = () => (
    <Icon name={ChevronRight24} color={Icon.Color.GreyscaleLevel3} />
  )

  const getSelectedSuffix = (groups: Array<FilterGroupModel<DynamicFilterOptionModel>>) => {
    const items = groups.flatMap(group => group.items)

    return compact(
      selectedIds.map(selectedId => items.find(({ id }) => selectedId === id)?.title),
    ).join(', ')
  }

  const renderCatalogCellSuffix = (groups: Array<FilterGroupModel<DynamicFilterOptionModel>>) => (
    <FilterCellSuffix
      suffixText={getSelectedSuffix(groups)}
      textTheme="primary"
      renderSuffix={renderCatalogCellSuffixIcon}
    />
  )

  const renderItemCell = ({
    item,
    itemElementProps,
  }: RenderItemProps<DynamicFilterOptionModel>) => (
    <DynamicFilterCell
      key={item.id}
      type={type}
      item={item}
      itemElementProps={itemElementProps}
      handleItemView={getHandleItemView(item)}
    />
  )

  const renderDefaultModal = () => {
    return (
      <FilterModal
        renderNavigation={renderModalNavigation(title, OptionType.Root)}
        isOpen={isModalOpen}
        onSubmit={handleSubmitClick}
        testId={`${type}-filter-modal`}
      >
        <SelectableItemList
          name={`${type}_ids`}
          items={listItems}
          selected={selectedIds}
          onItemClick={handleDefaultItemClick}
          renderItem={renderItemCell}
          isMultiSelect={selectionType === SelectionType.Multi}
          disableButtonClicks
        />
        <Divider />
      </FilterModal>
    )
  }

  const renderNavigationalItem = (catalogCode: string) => {
    const currentCatalog = navigationalItems.find(({ code }) => code === catalogCode)
    const currentTitle = currentCatalog?.title || title
    const groups = getGroupItems(catalogCode)
    const groupIds = groups?.flatMap(({ items }) => items.map(({ id }) => id)) || []

    return (
      <FilterModal
        renderNavigation={renderModalNavigation(currentTitle, catalogCode, groupIds)}
        isOpen={isModalOpen}
        key={catalogCode}
        onSubmit={handleSubmitClick}
        testId={`${type}-filter-modal`}
      >
        {groups.length ? (
          <FilterGroupList
            name={`${type}_ids`}
            groups={groups}
            isMultiSelect={selectionType === SelectionType.Multi}
            selectedIds={selectedIds}
            onItemClick={handelGroupItemClick}
            renderItem={renderItemCell}
          />
        ) : (
          <FilterCatalogList
            catalogs={navigationalItems}
            onItemClick={handelNavItemClick}
            renderSuffix={renderCatalogCellSuffix}
          />
        )}
        <Divider />
      </FilterModal>
    )
  }

  const renderNavigationalModal = () =>
    selectedCatalogCodes.map(code => renderNavigationalItem(code))

  const renderModal = () => {
    if (rootOptionType === OptionType.Group || rootOptionType === OptionType.Navigational) {
      return renderNavigationalModal()
    }

    return renderDefaultModal()
  }

  if (isHidden || !options.length) return null

  return (
    <>
      <FilterCell
        title={renderTitle()}
        suffixText={suffixText || translate('catalog.filters.dynamic.all')}
        suffixTextTheme={suffixText ? 'primary' : undefined}
        onClick={handleFilterCellClick}
        onView={onFilterCellView}
        visible={isFilterCellVisible}
      />
      {renderModal()}
    </>
  )
}

export default ListFilterModal
