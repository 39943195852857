import { useState, useEffect, useCallback } from 'react'
import { compact } from 'lodash'

import { WEB_CATALOG_ROOT_ALL_CODE } from 'constants/catalog'
import { catalogModelsToCatalogListItems, catalogsInAscOrder } from 'libs/utils/catalog'
import { CatalogItem } from 'types/catalog-filters'
import { CatalogModel } from 'types/models/catalog'

const useCatalogFilter = (
  openedCatalogsIds: Array<number>,
  rootCatalogTitle: string,
  allCatalogs: Record<number, CatalogModel>,
) => {
  const [allShownCatalogs, setAllShownCatalogs] = useState<
    Record<number, Array<CatalogItem> | undefined>
  >({})

  const getChildCatalogsList = useCallback(
    (parentId: number) => {
      const parent = allCatalogs[parentId]
      const parentCatalog = parent
        ? {
            ...parent,
            title: rootCatalogTitle,
            code: WEB_CATALOG_ROOT_ALL_CODE,
            // 'All' catalog is placed alongside its children in the list.
            // Therefore, it must match children's depth.
            depth: parent.depth + 1,
          }
        : null

      const catalogs = compact([
        parentCatalog,
        ...Object.values(allCatalogs)
          .filter(catalog => catalog.parentId === parentId)
          .sort(catalogsInAscOrder),
      ])

      return catalogModelsToCatalogListItems(catalogs)
    },
    [rootCatalogTitle, allCatalogs],
  )

  useEffect(() => {
    const newShownCatalogs: Record<number, Array<CatalogItem>> = {}

    openedCatalogsIds.forEach(id => {
      newShownCatalogs[id] = getChildCatalogsList(id)
    })

    setAllShownCatalogs(newShownCatalogs)
  }, [openedCatalogsIds, getChildCatalogsList])

  return { allShownCatalogs }
}

export default useCatalogFilter
