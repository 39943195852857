'use client'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Text } from '@vinted/web-ui'

import { getUserId } from 'state/session/selectors'
import { getCatalogBrand } from 'state/catalog-filters/selectors'
import useFollowBrand from 'hooks/useFollowBrand'

const BrandButton = () => {
  const currentUserId = useSelector(getUserId)
  const brand = useSelector(getCatalogBrand)

  const { isFollowed, toggleFollow } = useFollowBrand({
    brandId: brand?.id || 0,
    isFollowed: brand?.isFavourite || false,
  })

  function handleClick() {
    if (!brand) return

    toggleFollow()
  }

  if (!currentUserId) return null
  if (!brand) return null

  const translationKey = isFollowed ? 'unfollow' : 'follow'

  return (
    <button type="button" onClick={handleClick}>
      <Text
        as="span"
        theme="primary"
        type={Text.Type.Caption}
        underline={false}
        clickable
        role="none"
      >
        <span className="brand-button">
          <FormattedMessage id={`catalog.brand.actions.${translationKey}`} />
        </span>
      </Text>
    </button>
  )
}

export default BrandButton
