'use client'

import { compact } from 'lodash'
import { useMemo, useState, useEffect, useRef } from 'react'
import { Spacer, Badge, Text, Icon, Divider } from '@vinted/web-ui'
import { ArrowLeft24, ChevronRight24 } from '@vinted/monochrome-icons'
import { useSelector } from 'react-redux'
import uuid from 'uuid'

import { OptionType } from 'constants/dynamic-filters'
import FilterCell from 'components/FilterCell'
import { flattenDynamicFilterOptions } from 'state/catalog-filters/utils'
import { DynamicFilterOptionModel } from 'types/models/dynamic-filter'
import useTranslate from 'hooks/useTranslate'
import FilterModal from 'components/FilterModal'
import FilterModalNavigation from 'components/FilterModalNavigation'
import FilterCatalogList from 'components/FilterCatalogList/FilterCatalogList'
import { transformDynamicFilterNavigationalOptions } from 'state/catalog-filters/transformers'
import { FilterCatalogModel } from 'types/models/filter-catalog'
import { FilterGroupModel } from 'types/models/filter-group'
import FilterCellSuffix from 'components/FilterCell/FilterCellSuffix'
import { Screen } from 'constants/tracking/screens'
import {
  userClickDynamicFilterOptionEvent,
  userViewDynamicFilterOptionEvent,
  clickEvent,
} from 'libs/common/event-tracker/events'
import { getGlobalSearchSessionId, getSearchSessionId } from 'state/catalog-filters/selectors'
import useTracking from 'hooks/useTracking'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import GridGroup from '../Common/GridGroup'
import VerticalPadder from '../Common/VerticalPadder'

type Props = {
  type: string
  title: string
  isSelectionHighlighted?: boolean
  isNewFilter?: boolean
  options: Array<DynamicFilterOptionModel>
  selectedIds: Array<number>
  isFilterCellVisible: boolean
  fromScreen: Screen
  rootOptionType: OptionType
  isHidden?: boolean
  onListOptionSelect: (id: number) => void
  clearSelectedOptions: (type: string, ids?: Array<number>) => void
  onSubmit: () => void
  onFilterCellClick: () => void
  onFilterCellView: () => void
  onModalOpen: () => void
  onModalClose: () => void
}

const GridFilterModal = ({
  type,
  title,
  isSelectionHighlighted,
  isNewFilter,
  options,
  selectedIds,
  isFilterCellVisible,
  rootOptionType,
  fromScreen,
  isHidden,
  onListOptionSelect,
  clearSelectedOptions,
  onSubmit,
  onFilterCellClick,
  onFilterCellView,
  onModalOpen,
  onModalClose,
}: Props) => {
  const translate = useTranslate()
  const { track } = useTracking()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCatalogCode, setSelectedCatalogCode] = useState<string>('')
  const [highlightedSelectedIds, setHighlightedSelectedIds] = useState<Array<number>>(selectedIds)

  const seenItems = useRef(new Set<number>())
  const filteringSessionId = useRef('')
  const searchSessionId = useSelector(getSearchSessionId)
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const screen = `${Screen.DynamicFilter}_${type}`

  useEffect(() => {
    if (isModalOpen) {
      filteringSessionId.current = uuid.v4()

      return
    }

    seenItems.current.clear()
    filteringSessionId.current = ''
  }, [isModalOpen])

  useEffect(() => {
    if (rootOptionType === OptionType.Navigational) {
      setSelectedCatalogCode('')

      return
    }

    setSelectedCatalogCode(OptionType.Root)
  }, [rootOptionType])

  const flattedOptions = useMemo(() => {
    return flattenDynamicFilterOptions(options)
  }, [options])

  const navigationalItems = useMemo(() => {
    return transformDynamicFilterNavigationalOptions(
      options,
      rootOptionType,
      isSelectionHighlighted,
      highlightedSelectedIds,
    )
  }, [options, rootOptionType, isSelectionHighlighted, highlightedSelectedIds])

  const rootSuffixText = useMemo(() => {
    return compact(
      selectedIds.map(selectedId => flattedOptions.find(option => option.id === selectedId)?.title),
    ).join(', ')
  }, [selectedIds, flattedOptions])

  const navigatedRoot = useMemo(() => {
    return options.find(item => selectedCatalogCode === `${item.type}_${item.id}`)
  }, [options, selectedCatalogCode])

  const navigatedGroups = useMemo(() => {
    if (selectedCatalogCode === OptionType.Root) return options

    return navigatedRoot?.options || []
  }, [navigatedRoot?.options, options, selectedCatalogCode])

  const navigatedGroupsSelected = useMemo(() => {
    return navigatedGroups
      ?.map(item => ({
        ...item,
        options: item.options.filter(option => highlightedSelectedIds.includes(option.id)),
      }))
      .filter(item => item.options.length > 0)
  }, [highlightedSelectedIds, navigatedGroups])

  const groupToCount = useMemo(() => {
    const map = new Map<number, number>()

    navigatedGroups?.forEach(group => {
      let count = 0

      group.options.forEach(item => {
        if (selectedIds.includes(item.id)) {
          count += 1
        }
      })

      map.set(group.id, count)
    })

    return map
  }, [navigatedGroups, selectedIds])

  const getItemPosition = (id: number) => {
    return flattedOptions.findIndex(item => item.id === id) + 1
  }

  const openModal = () => {
    setIsModalOpen(true)
    setHighlightedSelectedIds(selectedIds)
    onModalOpen()
  }

  const closeModal = () => {
    setIsModalOpen(false)
    onModalClose()
  }

  const handleFilterCellClick = () => {
    openModal()
    onFilterCellClick()
  }

  const handleNavClick = (catalog: FilterCatalogModel) => {
    setSelectedCatalogCode(catalog.code)
  }

  const handleNavBackClick = () => {
    setSelectedCatalogCode('')
    setHighlightedSelectedIds(selectedIds)
  }

  const handleSubmitClick = () => {
    closeModal()
    onSubmit()

    track(
      clickEvent({
        screen,
        target: ClickableElement.ApplyFilters,
      }),
    )
  }

  const handleSelect = (item: DynamicFilterOptionModel) => {
    onListOptionSelect(item.id)

    track(
      userClickDynamicFilterOptionEvent({
        id: item.id,
        type,
        code: type,
        position: getItemPosition(item.id),
        itemsCount: item.itemsCount,
        isSelected: !selectedIds.includes(item.id),
        filteringSessionId: filteringSessionId.current,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )
  }

  const handleInViewItem = (item: DynamicFilterOptionModel) => {
    if (seenItems.current.has(item.id)) return
    seenItems.current.add(item.id)

    track(
      userViewDynamicFilterOptionEvent({
        id: item.id,
        type,
        code: type,
        position: getItemPosition(item.id),
        itemsCount: item.itemsCount,
        filteringSessionId: filteringSessionId.current,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )
  }

  const clearSelectedOptionIds = () => {
    track(
      clickEvent({
        target: ClickableElement.ClearFilters,
        screen,
      }),
    )

    if (!navigatedRoot) {
      clearSelectedOptions(
        type,
        flattedOptions.map(item => item.id),
      )

      return
    }

    clearSelectedOptions(
      type,
      navigatedGroups.flatMap(group => group.options).map(item => item.id),
    )
  }

  const getSelectedSuffix = (groups: Array<FilterGroupModel<DynamicFilterOptionModel>>) => {
    const items = groups.flatMap(group => group.items)

    return compact(
      selectedIds.map(selectedId => items.find(({ id }) => selectedId === id)?.title),
    ).join(', ')
  }

  const renderTitle = () => (
    <>
      <Text text={title} type={Text.Type.Title} as="span" />
      {isNewFilter && (
        <>
          <Spacer orientation={Spacer.Orientation.Vertical} />
          <Badge theme="primary" content={translate('common.new_badge')} />
        </>
      )}
    </>
  )

  const renderModalNavigation = () => {
    const modalTitle = navigatedRoot?.title || title
    const modalPrefixClick = navigatedRoot ? handleNavBackClick : closeModal

    return (
      <FilterModalNavigation
        title={modalTitle}
        icon={<Icon name={ArrowLeft24} testId={`${type}-navigation-prefix-icon`} />}
        prefixAriaLabel={translate('catalog.filters.a11y.actions.back')}
        suffixText={translate('catalog.filters.dynamic.clear')}
        onPrefixClick={modalPrefixClick}
        onSuffixClick={clearSelectedOptionIds}
      />
    )
  }

  const renderCatalogCellSuffix = (groups: Array<FilterGroupModel<DynamicFilterOptionModel>>) => (
    <FilterCellSuffix
      suffixText={getSelectedSuffix(groups)}
      textTheme="primary"
      renderSuffix={() => <Icon name={ChevronRight24} color={Icon.Color.GreyscaleLevel3} />}
    />
  )

  const renderContentHeader = () => {
    if (!selectedCatalogCode) return undefined
    if ((navigatedGroupsSelected?.length || 0) < 1) return undefined
    if (!isSelectionHighlighted) return undefined

    return (
      <>
        <Spacer size={Spacer.Size.Large} />
        <VerticalPadder>
          <Text
            text={translate('catalog.filters.dynamic.applied_filters')}
            type={Text.Type.Subtitle}
            as="span"
          />
        </VerticalPadder>

        {navigatedGroupsSelected?.map(gridItem => (
          <GridGroup
            key={gridItem.id}
            option={gridItem}
            selectedIds={selectedIds}
            onSelect={handleSelect}
            onInViewItem={handleInViewItem}
          />
        ))}

        <Spacer size={Spacer.Size.Large} />
        <Divider />
      </>
    )
  }

  const renderContent = () => {
    if (!selectedCatalogCode) {
      return (
        <FilterCatalogList
          renderSuffix={renderCatalogCellSuffix}
          catalogs={navigationalItems}
          onItemClick={handleNavClick}
        />
      )
    }

    return navigatedGroups?.map(gridItem => (
      <GridGroup
        key={gridItem.id}
        isSticky
        selectedCount={groupToCount.get(gridItem.id)}
        option={gridItem}
        selectedIds={selectedIds}
        onSelect={handleSelect}
        onInViewItem={handleInViewItem}
      />
    ))
  }

  if (isHidden) return null

  return (
    <>
      <FilterCell
        title={renderTitle()}
        suffixText={rootSuffixText || translate('catalog.filters.dynamic.all')}
        suffixTextTheme={rootSuffixText ? 'primary' : undefined}
        onClick={handleFilterCellClick}
        onView={onFilterCellView}
        visible={isFilterCellVisible}
      />

      <FilterModal
        renderNavigation={renderModalNavigation}
        isOpen={isModalOpen}
        onSubmit={handleSubmitClick}
        testId={`${type}-filter-modal`}
      >
        {renderContentHeader()}
        {renderContent()}
      </FilterModal>
    </>
  )
}

export default GridFilterModal
