'use client'

import { Button, Cell, Modal, Spacer, Text } from '@vinted/web-ui'

import FaqEntryUrl from 'components/FaqEntryUrl'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { FaqEntryType } from 'constants/faq-entry'
import { AccessChannel } from 'constants/index'
import { OUR_PLATFORM_URL } from 'constants/routes'

type Props = {
  show: boolean
  onConfirmOrClose: () => void
}

const SearchResultsRankingModal = ({ show, onConfirmOrClose }: Props) => {
  const translate = useTranslate('catalog.search_results.ranking_modal')
  const { track } = useTracking()

  const handleSearchResultsLearnMoreClick = () => {
    track(clickEvent({ target: ClickableElement.HelpCenterSearchResult }))
  }

  const handleBumpsLearnMoreClick = () => {
    track(clickEvent({ target: ClickableElement.PushUpLearnMore }))
  }

  const renderBody = () => (
    <Cell styling={Cell.Styling.Tight}>
      <Text text={translate('subtitle')} type={Text.Type.Title} />
      <Spacer size={Spacer.Size.Medium} />
      <Text html text={translate('body.one')} type={Text.Type.Body} />
      <Spacer size={Spacer.Size.Medium} />
      <Text html text={translate('body.two')} type={Text.Type.Body} />
      <Spacer size={Spacer.Size.Medium} />
      <a
        href={OUR_PLATFORM_URL}
        onClick={handleSearchResultsLearnMoreClick}
        target="_blank"
        rel="noreferrer"
      >
        {translate('link')}
      </a>
      <Spacer size={Spacer.Size.X3Large} />
      <Text text={translate('bumps.subtitle')} type={Text.Type.Title} />
      <Spacer size={Spacer.Size.Medium} />
      <Text html text={translate('bumps.body')} type={Text.Type.Body} />
      <Spacer size={Spacer.Size.Medium} />
      <FaqEntryUrl
        type={FaqEntryType.PushUp}
        accessChannel={AccessChannel.ProductLink}
        render={url => (
          <a
            data-testid="bumps-faq-link"
            href={url}
            onClick={handleBumpsLearnMoreClick}
            target="_blank"
            rel="noreferrer"
          >
            {translate('bumps.link')}
          </a>
        )}
      />
      <Spacer size={Spacer.Size.X3Large} />
      <Button
        text={translate('actions.confirm')}
        styling={Button.Styling.Filled}
        size={Button.Size.Medium}
        onClick={onConfirmOrClose}
      />
      <Spacer size={Spacer.Size.Medium} />
    </Cell>
  )

  return (
    <Modal
      enableCloseButton
      hasScrollableContent
      className="search-results-ranking-modal"
      body={renderBody()}
      defaultCallback={onConfirmOrClose}
      show={show}
      testId="search-results-ranking-modal"
      title={translate('title')}
    />
  )
}

export default SearchResultsRankingModal
