'use client'

import { useState, useRef, useEffect, useMemo, ChangeEvent, FormEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { compact } from 'lodash'
import uuid from 'uuid'
import { Divider, EmptyState, InputBar, Icon } from '@vinted/web-ui'
import { ArrowLeft24, Search16 } from '@vinted/monochrome-icons'

import { fetchDynamicFilterSearchRequest } from 'state/catalog-filters/actions'
import { actions } from 'state/catalog-filters/slice'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { OptionType, SelectionType } from 'constants/dynamic-filters'
import { DynamicFilterOptionModel, SearchTranslationsModel } from 'types/models/dynamic-filter'

import { getGlobalSearchSessionId, getSearchSessionId } from 'state/catalog-filters/selectors'

import FilterCell from 'components/FilterCell'
import FilterModal from 'components/FilterModal'
import FilterModalNavigation from 'components/FilterModalNavigation'
import SelectableItemList from 'components/SelectableItemList'
import { SelectableListItem } from 'types/components'
import { RenderItemProps } from 'components/SelectableItemList/SelectableItemList'
import DynamicFilterCell from 'components/DynamicFilters/common/DynamicFilterCell'
import {
  userClickDynamicFilterOptionEvent,
  userViewDynamicFilterOptionEvent,
  clickEvent,
} from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { transformDynamicFilterDefaultOptions } from 'state/catalog-filters/transformers'
import { flattenDynamicFilterOptions } from 'state/catalog-filters/utils'

type ListOptionItem = SelectableListItem<DynamicFilterOptionModel>

type Props = {
  type: string
  title: string
  selectionType: SelectionType
  isSelectionHighlighted?: boolean
  options: Array<DynamicFilterOptionModel>
  selectedIds: Array<number>
  fromScreen: Screen
  isFilterCellVisible: boolean
  rootOptionType: OptionType
  translations?: SearchTranslationsModel
  isHidden?: boolean
  onListOptionSelect: (id: number) => void
  clearSelectedOptions: (type: string, ids?: Array<number>) => void
  onSubmit: () => void
  onFilterCellClick: () => void
  onFilterCellView: () => void
  onModalOpen: () => void
  onModalClose: () => void
}

const SearchFilterModal = ({
  type,
  title,
  selectionType,
  isSelectionHighlighted,
  options,
  selectedIds,
  fromScreen,
  isFilterCellVisible,
  rootOptionType,
  translations,
  isHidden,
  onListOptionSelect,
  clearSelectedOptions,
  onSubmit,
  onFilterCellClick,
  onFilterCellView,
  onModalOpen,
  onModalClose,
}: Props) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const { track } = useTracking()
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const searchSessionId = useSelector(getSearchSessionId)
  const seenItems = useRef<Array<number>>([])
  const [query, setQuery] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [highlightedSelectedIds, setHighlightedSelectedIds] = useState<Array<number>>(selectedIds)
  const [filteringSessionId, setFilteringSessionId] = useState('')
  const screen = `${Screen.DynamicFilter}_${type}`

  const flattedOptions = useMemo(() => {
    return flattenDynamicFilterOptions(options)
  }, [options])

  const suffixText = useMemo(() => {
    return compact(
      selectedIds.map(selectedId => flattedOptions.find(option => option.id === selectedId)?.title),
    ).join(', ')
  }, [selectedIds, flattedOptions])

  const listItems = useMemo(() => {
    return transformDynamicFilterDefaultOptions(
      options,
      rootOptionType,
      isSelectionHighlighted,
      highlightedSelectedIds,
    )
  }, [options, rootOptionType, isSelectionHighlighted, highlightedSelectedIds])

  const closeModal = () => {
    setFilteringSessionId('')
    seenItems.current = []

    setIsModalOpen(false)
    onModalClose()
  }

  const openModal = () => {
    setIsModalOpen(true)

    setHighlightedSelectedIds(selectedIds)
    onModalOpen()
  }

  const getCurrentPosition = (id: number) =>
    flattedOptions.findIndex(option => option.id === id) + 1

  const handleBackClick = (catalogCode: string) => () => {
    if (catalogCode === OptionType.Root) closeModal()

    seenItems.current = []
    setHighlightedSelectedIds(selectedIds)

    if (query) setQuery('')
  }

  const handleItemClick = (id: number, position: number, itemsCount?: number) => {
    onListOptionSelect(id)

    track(
      userClickDynamicFilterOptionEvent({
        id,
        type,
        code: type,
        position,
        itemsCount,
        isSelected: !selectedIds.includes(id),
        searchText: query || '',
        filteringSessionId,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )
  }

  const handleDefaultItemClick = (item: ListOptionItem) => {
    const id = Number(item.id)
    const position = getCurrentPosition(id)
    const itemsCount = item.data?.itemsCount

    handleItemClick(id, position, itemsCount)
  }

  const handleSubmitClick = () => {
    closeModal()
    onSubmit()

    track(
      clickEvent({
        screen,
        target: ClickableElement.ApplyFilters,
      }),
    )

    if (query) setQuery('')
  }

  const clearSelectedOptionIds = (groupIds?: Array<number>) => () => {
    clearSelectedOptions(type, groupIds)

    if (query) setQuery('')

    track(
      clickEvent({
        target: ClickableElement.ClearFilters,
        screen,
      }),
    )
  }

  const handleFilterCellClick = () => {
    openModal()
    onFilterCellClick()
  }

  const getHandleItemView = (item: ListOptionItem) => (inView: boolean) => {
    if (!inView) return

    const itemId = Number(item.id)
    if (seenItems.current.includes(itemId)) return

    const position = getCurrentPosition(itemId)

    track(
      userViewDynamicFilterOptionEvent({
        id: itemId,
        type,
        code: type,
        position,
        itemsCount: item.data?.itemsCount,
        searchText: query || '',
        filteringSessionId,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )

    seenItems.current = [...seenItems.current, itemId]
  }

  const handleSearchInput = (
    event: ChangeEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>,
  ) => {
    const searchQuery = event.currentTarget.value
    setQuery(searchQuery)

    if (searchQuery) {
      dispatch(actions.setFilterSearchQuery({ text: searchQuery, code: type }))
      dispatch(fetchDynamicFilterSearchRequest(type, searchQuery))
    }

    setHighlightedSelectedIds(selectedIds)
  }

  const clearSearchInput = () => {
    if (query) setQuery('')
  }

  useEffect(() => {
    if (isModalOpen) setFilteringSessionId(uuid.v4())
  }, [isModalOpen])

  useEffect(() => {
    if (query !== '') return

    // clear up the query and search result if user clear the search text
    dispatch(fetchDynamicFilterSearchRequest(type, ''))
    dispatch(actions.setFilterSearchQuery({ text: null, code: null }))
  }, [query, type, dispatch])

  const renderInputBarIcon = () => (
    <div className="u-flexbox u-align-items-center">
      <Icon name={Search16} color={Icon.Color.GreyscaleLevel4} />
    </div>
  )

  const renderModalNavigation =
    (currentTitle: string, catalogCode: string, groupIds?: Array<number>) => () => {
      return (
        <>
          <FilterModalNavigation
            title={currentTitle}
            icon={<Icon name={ArrowLeft24} testId={`${type}-navigation-prefix-icon`} />}
            prefixAriaLabel={translate('catalog.filters.a11y.actions.back')}
            suffixText={translate('catalog.filters.dynamic.clear')}
            onPrefixClick={handleBackClick(catalogCode)}
            onSuffixClick={clearSelectedOptionIds(groupIds)}
          />
          <div className="brand-filter__navigation-input">
            <InputBar
              name={`${type}_filter_search`}
              placeholder={translations?.placeholder || ''}
              value={query}
              icon={renderInputBarIcon()}
              onChange={handleSearchInput}
              onValueClear={clearSearchInput}
              inputAria={{
                'aria-label': title,
              }}
              clearButtonAria={{
                'aria-label': translate('catalog.filters.dynamic.clear'),
              }}
            />
          </div>
        </>
      )
    }

  const renderItemCell = ({
    item,
    itemElementProps,
  }: RenderItemProps<DynamicFilterOptionModel>) => (
    <DynamicFilterCell
      key={item.id}
      type={type}
      item={item}
      itemElementProps={itemElementProps}
      handleItemView={getHandleItemView(item)}
    />
  )

  const renderEmptyState = () => (
    <EmptyState title={translations?.noResults?.title} body={translations?.noResults?.body} />
  )

  const renderModal = () => {
    return (
      <FilterModal
        renderNavigation={renderModalNavigation(title, OptionType.Root)}
        isOpen={isModalOpen}
        onSubmit={handleSubmitClick}
        testId={`${type}-filter-modal`}
      >
        {listItems.length ? (
          <>
            <SelectableItemList
              name={`${type}_ids`}
              items={listItems}
              selected={selectedIds}
              onItemClick={handleDefaultItemClick}
              renderItem={renderItemCell}
              isMultiSelect={selectionType === SelectionType.Multi}
              disableButtonClicks
            />
            <Divider />
          </>
        ) : (
          renderEmptyState()
        )}
      </FilterModal>
    )
  }

  if (isHidden) return null

  return (
    <>
      <FilterCell
        title={title}
        suffixText={suffixText || translate('catalog.filters.dynamic.all')}
        suffixTextTheme={suffixText ? 'primary' : undefined}
        onClick={handleFilterCellClick}
        onView={onFilterCellView}
        visible={isFilterCellVisible}
      />
      {renderModal()}
    </>
  )
}

export default SearchFilterModal
