'use client'

import { useRef, useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import uuid from 'uuid'
import { Button, Divider, Icon, Navigation } from '@vinted/web-ui'
import { ArrowLeft24 } from '@vinted/monochrome-icons'

import { flattenDynamicFilterOptions } from 'state/catalog-filters/utils'
import {
  transformDynamicFilterDefaultOptions,
  transformDynamicFilterGroupOptions,
  transformDynamicFilterNavigationalOptions,
} from 'state/catalog-filters/transformers'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import ScrollableArea from 'components/ScrollableArea'
import FilterDropdown from 'components/FilterDropdown'
import SelectableItemList from 'components/SelectableItemList'
import { OptionType, SelectionType } from 'constants/dynamic-filters'
import { DynamicFilterOptionModel, ExperimentModel } from 'types/models/dynamic-filter'
import { SelectableListItem } from 'types/components'

import { getGlobalSearchSessionId, getSearchSessionId } from 'state/catalog-filters/selectors'
import { getExposee } from 'state/session/selectors'

import { RenderItemProps } from 'components/SelectableItemList/SelectableItemList'
import {
  abTestExposeEvent,
  userClickDynamicFilterOptionEvent,
  userViewDynamicFilterOptionEvent,
} from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { SELECT_DROPDOWN_MAX_HEIGHT, SELECT_DROPDOWN_MIN_WIDTH } from 'constants/filter'
import { FilterCatalogModel } from 'types/models/filter-catalog'
import FilterGroupList from 'components/FilterGroupList/FilterGroupList'
import FilterCatalogList from 'components/FilterCatalogList/FilterCatalogList'
import DynamicFilterCell from 'components/DynamicFilters/common/DynamicFilterCell'

type ListOptionItem = SelectableListItem<DynamicFilterOptionModel>

type Props = {
  type: string
  title: string
  selectionType: SelectionType
  isSelectionHighlighted?: boolean
  isHidden?: boolean
  experiment?: ExperimentModel
  rootOptionType: OptionType
  options: Array<DynamicFilterOptionModel>
  selectedIds: Array<number>
  fromScreen: Screen
  onListOptionSelect: (id: number) => void
}

const ListFilterDropdown = ({
  type,
  title,
  selectionType,
  isSelectionHighlighted,
  isHidden,
  experiment,
  rootOptionType,
  options,
  selectedIds,
  fromScreen,
  onListOptionSelect,
}: Props) => {
  const { track } = useTracking()
  const translate = useTranslate()
  const exposee = useSelector(getExposee)
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const searchSessionId = useSelector(getSearchSessionId)
  const seenItems = useRef<Array<number>>([])
  const [selectedCatalogCode, setSelectedCatalogCode] = useState<string | null>(null)
  const [highlightedSelectedIds, setHighlightedSelectedIds] = useState<Array<number>>(selectedIds)
  const filteringSessionId = useMemo(() => uuid.v4(), [])

  useEffect(() => {
    if (experiment) {
      track(abTestExposeEvent({ ...exposee, ...experiment }))
    }
  }, [experiment, exposee, track])

  const flattedOptions = useMemo(() => {
    return flattenDynamicFilterOptions(options)
  }, [options])

  const listItems = useMemo(() => {
    return transformDynamicFilterDefaultOptions(
      options,
      rootOptionType,
      isSelectionHighlighted,
      highlightedSelectedIds,
    )
  }, [options, rootOptionType, isSelectionHighlighted, highlightedSelectedIds])

  const navigationalItems = useMemo(() => {
    return transformDynamicFilterNavigationalOptions(
      options,
      rootOptionType,
      isSelectionHighlighted,
      highlightedSelectedIds,
    )
  }, [options, rootOptionType, isSelectionHighlighted, highlightedSelectedIds])

  const getGroupItems = (catalogCode: string) => {
    const currentCatalog = navigationalItems.find(({ code }) => code === catalogCode)
    if (currentCatalog) {
      return currentCatalog.matchingGroups
    }

    return transformDynamicFilterGroupOptions(
      options,
      rootOptionType,
      isSelectionHighlighted,
      highlightedSelectedIds,
    )
  }

  const getCurrentPosition = (id: number) =>
    flattedOptions.findIndex(option => option.id === id) + 1

  const handleItemClick = (id: number, position: number, itemsCount?: number) => {
    onListOptionSelect(id)

    track(
      userClickDynamicFilterOptionEvent({
        id,
        type,
        code: type,
        position,
        isSelected: !selectedIds.includes(id),
        itemsCount,
        filteringSessionId,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )
  }

  const getHandleItemView = (item: ListOptionItem) => (inView: boolean) => {
    if (!inView) return

    const id = Number(item.id)
    if (seenItems.current.includes(id)) return

    const position = getCurrentPosition(id)

    track(
      userViewDynamicFilterOptionEvent({
        id,
        type,
        code: type,
        position,
        itemsCount: item.data?.itemsCount,
        filteringSessionId,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )

    seenItems.current = [...seenItems.current, id]
  }

  const handleDefaultItemClick = (item: ListOptionItem) => {
    const id = Number(item.id)
    const position = getCurrentPosition(id)
    const itemsCount = item.data?.itemsCount

    handleItemClick(id, position, itemsCount)
  }

  const handelGroupItemClick = (id: number) => {
    const position = getCurrentPosition(id)

    handleItemClick(id, position)
  }

  const handelNavItemClick = (catalog: FilterCatalogModel) => {
    setSelectedCatalogCode(catalog.code)
  }

  const handleOpenDropdown = () => {
    seenItems.current = []

    if (!selectedCatalogCode && rootOptionType === OptionType.Group) {
      setSelectedCatalogCode(OptionType.Root)
    }
    if (selectedCatalogCode === OptionType.Root && rootOptionType === OptionType.Navigational) {
      setSelectedCatalogCode(null)
    }
  }

  const handleCloseDropdown = () => {
    setHighlightedSelectedIds(selectedIds)
  }

  const handleBackClick = () => {
    setHighlightedSelectedIds(selectedIds)
    setSelectedCatalogCode(null)
  }

  const renderNavigationHeader = () => {
    const filter = navigationalItems.find(catalog => catalog.code === selectedCatalogCode)
    if (!filter || navigationalItems.length === 1) return null

    return (
      <>
        <Navigation
          left={
            <Button
              styling={Button.Styling.Flat}
              theme="amplified"
              icon={<Icon name={ArrowLeft24} />}
              onClick={handleBackClick}
              testId={`${type}-dropdown-go-back`}
              aria={{
                'aria-label': translate('catalog.filters.a11y.actions.back'),
              }}
            />
          }
          body={filter.title}
        />
        <Divider />
      </>
    )
  }

  const renderItemCell = ({
    item,
    itemElementProps,
  }: RenderItemProps<DynamicFilterOptionModel>) => (
    <DynamicFilterCell
      key={item.id}
      type={type}
      item={item}
      itemElementProps={itemElementProps}
      handleItemView={getHandleItemView(item)}
    />
  )

  const renderDefaultDropdown = () => (
    <SelectableItemList
      name={`${type}_ids`}
      isMultiSelect={selectionType === SelectionType.Multi}
      items={listItems}
      selected={selectedIds}
      renderItem={renderItemCell}
      onItemClick={handleDefaultItemClick}
      disableButtonClicks
    />
  )

  const renderNavigationalDropdown = () =>
    selectedCatalogCode ? (
      <FilterGroupList
        name={`${type}_ids`}
        isMultiSelect={selectionType === SelectionType.Multi}
        renderItem={renderItemCell}
        groups={getGroupItems(selectedCatalogCode)}
        selectedIds={selectedIds}
        onItemClick={handelGroupItemClick}
      />
    ) : (
      <FilterCatalogList catalogs={navigationalItems} onItemClick={handelNavItemClick} />
    )

  const renderDropdown = () => {
    if (rootOptionType === OptionType.Group || rootOptionType === OptionType.Navigational) {
      return renderNavigationalDropdown()
    }

    return renderDefaultDropdown()
  }

  if (isHidden) return null

  return (
    <div className="u-ui-margin-right-regular u-ui-margin-bottom-regular">
      <FilterDropdown
        title={title}
        areAnyOfItemsSelected={!!selectedIds.length}
        testId={`catalog--${type}-filter`}
        minWidth={SELECT_DROPDOWN_MIN_WIDTH}
        onOpen={handleOpenDropdown}
        onClose={handleCloseDropdown}
        useChipAsTrigger
      >
        <div className="u-ui-padding-vertical-x-small">
          {renderNavigationHeader()}
          <ScrollableArea maxHeight={SELECT_DROPDOWN_MAX_HEIGHT}>{renderDropdown()}</ScrollableArea>
        </div>
      </FilterDropdown>
    </div>
  )
}

export default ListFilterDropdown
