'use client'

import { ReactNode, useEffect, useRef, useState, useCallback } from 'react'
import {
  useFloating,
  offset,
  flip,
  shift,
  Card,
  Button,
  Chip,
  Text,
  Spacing,
  Icon,
} from '@vinted/web-ui'
import { noop, isFunction } from 'lodash'
import { Dropdown16, ChevronUp16, ChevronDown16 } from '@vinted/monochrome-icons'

import OutsideClick from 'components/OutsideClick'

const DROPDOWN_MIN_WIDTH = 240

type renderTriggerProps = {
  title: string | JSX.Element
  toggle: () => void
  buttonProps: ComponentProps<typeof Button>
  isOpen: boolean
}

type useFloatingArgs = Parameters<typeof useFloating>[0]

type Props = {
  children: ReactNode
  placement?: useFloatingArgs['placement']
  closeOnClick?: boolean
  title?: string | JSX.Element
  minWidth?: number | string
  areAnyOfItemsSelected?: boolean
  useChipAsTrigger?: boolean
  useForceClose?: boolean
  testId?: string
  renderTrigger?: (triggerProps: renderTriggerProps) => ReactNode
  onOpen?: () => void
  onClose?: () => void
  onFilterClick?: () => void
}

const FilterDropdown = ({
  children,
  closeOnClick,
  title = '',
  placement = 'bottom-start',
  minWidth = DROPDOWN_MIN_WIDTH,
  areAnyOfItemsSelected = false,
  useChipAsTrigger = false,
  useForceClose = false,
  testId = 'filter-dropdown',
  renderTrigger,
  onOpen = noop,
  onClose = noop,
  onFilterClick = noop,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const isInitialRender = useRef(true)

  const { floaterRef, triggerRef, floaterStyle } = useFloating({
    middleware: [offset(Spacing.Medium), flip({ mainAxis: false }), shift()],
    isFloaterVisible: isOpen,
    shouldUpdateOnAncestorScroll: false,
    shouldAutoUpdate: true,
    placement,
  })

  const close = useCallback(() => {
    if (!isOpen) return

    // Since onOutsideClick fires via mousedown event, events inside the dropdown
    // may not fire in time (for example, onBlur). Adding a timeout of 0 makes
    // the content of the dropdown disappear only on the next tick.
    setTimeout(() => {
      setIsOpen(false)
    }, 0)
  }, [isOpen])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false

      return
    }

    const callback = isOpen ? onOpen : onClose

    callback()
  }, [isOpen, onOpen, onClose])

  useEffect(() => {
    if (useForceClose) close()
  }, [useForceClose, close])

  const toggle = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)

    if (onFilterClick) onFilterClick()
  }

  const renderTriggerElement = () => {
    const sharedTriggerProps = {
      text: title,
      onClick: toggle,
      ref: triggerRef,
      testId: `${testId}--trigger`,
    }

    const buttonProps: ComponentProps<typeof Button> = {
      ...sharedTriggerProps,
      styling: Button.Styling.Flat,
      size: Button.Size.Medium,
      theme: 'muted',
      icon: <Icon name={Dropdown16} color={Icon.Color.GreyscaleLevel3} />,
      iconPosition: Button.IconPosition.Right,
    }

    if (renderTrigger && isFunction(renderTrigger)) {
      return renderTrigger({ toggle, buttonProps, title, isOpen })
    }

    if (!useChipAsTrigger) return <Button {...buttonProps} />

    return (
      <Chip
        {...sharedTriggerProps}
        textType={Text.Type.Subtitle}
        clicked={isOpen}
        activated={areAnyOfItemsSelected}
        suffix={<Icon name={isOpen ? ChevronUp16 : ChevronDown16} />}
        aria={{
          'aria-expanded': isOpen,
        }}
      />
    )
  }

  const renderContent = () => {
    if (!isOpen) return null

    const handleOnClick = closeOnClick ? close : undefined

    return (
      <div
        role="none"
        className="u-fill-width u-zindex-large"
        style={{ minWidth, ...floaterStyle }}
        onClick={handleOnClick}
        data-testid="dropdown-content"
        ref={floaterRef}
      >
        <Card styling={Card.Styling.Elevated}>{children}</Card>
      </div>
    )
  }

  return (
    <OutsideClick onOutsideClick={close}>
      <div className="u-position-relative">
        {renderTriggerElement()}
        {renderContent()}
      </div>
    </OutsideClick>
  )
}

export default FilterDropdown
