'use client'

import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Icon, Text } from '@vinted/web-ui'
import { ArrowLeft24 } from '@vinted/monochrome-icons'

import FilterCell from 'components/FilterCell'
import FilterModal from 'components/FilterModal'
import FilterModalNavigation from 'components/FilterModalNavigation'
import PriceRangeInput from 'components/PriceRangeInput'
import useTranslate from 'hooks/useTranslate'
import { CurrencyAmountModel } from 'types/models'
import { PriceRange } from 'state/catalog-filters/types'
import { getNewPriceRange } from 'libs/utils/catalog'
import { formatCurrency } from 'libs/utils/formatString'
import { getFilterCurrency, getCurrency } from 'state/catalog-filters/selectors'
import { getLocale } from 'state/intl/selectors'
import { State as AppState } from 'state/types'

const FALLBACK_PRICE_FROM_VALUE = '0'

type Props = {
  selectedPriceRange: PriceRange
  isFilterCellVisible: boolean
  minimumValidation?: CurrencyAmountModel
  onPriceRangeChange: (range: PriceRange) => void
  onSubmit: () => void
  onFilterCellClick: () => void
  onFilterCellView: () => void
  onModalOpen: () => void
  onModalClose: () => void
}

const PriceFilter = ({
  selectedPriceRange,
  isFilterCellVisible,
  minimumValidation,
  onPriceRangeChange,
  onSubmit,
  onFilterCellClick,
  onFilterCellView,
  onModalOpen,
  onModalClose,
}: Props) => {
  const translate = useTranslate('catalog')
  const { formatNumber } = useIntl()

  const locale = useSelector(getLocale)
  const currency = useSelector((state: AppState) => getFilterCurrency(state) || getCurrency(state))

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [shouldUpdatePrice, setShouldUpdatePrice] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
    onModalClose()
  }

  const openModal = () => {
    setIsModalOpen(true)
    setShouldUpdatePrice(true)
    onModalOpen()
  }

  const handleSubmitClick = () => {
    closeModal()
    onSubmit()
  }

  const clearPriceRange = () => {
    onPriceRangeChange({ priceFrom: null, priceTo: null })
    setShouldUpdatePrice(true)
  }

  const handlePriceRangeChange = ({ priceFrom, priceTo }: PriceRange) => {
    const newPriceFrom = priceTo ? priceFrom || FALLBACK_PRICE_FROM_VALUE : priceFrom

    setShouldUpdatePrice(true)
    onPriceRangeChange(getNewPriceRange(newPriceFrom, priceTo))
  }

  const handleFilterCellClick = () => {
    openModal()
    onFilterCellClick()
  }

  const renderModalNavigation = () => (
    <FilterModalNavigation
      title={translate('filters.price.name')}
      suffixText={translate('filters.price.clear_price')}
      prefixAriaLabel={translate('filters.a11y.actions.back')}
      icon={<Icon name={ArrowLeft24} testId="price-navigation-prefix-icon" />}
      onPrefixClick={closeModal}
      onSuffixClick={clearPriceRange}
    />
  )

  const renderPriceFilterModal = () => (
    <FilterModal
      renderNavigation={renderModalNavigation}
      isOpen={isModalOpen}
      onSubmit={handleSubmitClick}
      testId="price-modal"
    >
      <div className="u-flexbox">
        <PriceRangeInput
          priceRange={selectedPriceRange}
          priceFromTitle={translate('filters.price.price_from')}
          priceToTitle={translate('filters.price.price_to')}
          onPriceRangeChange={handlePriceRangeChange}
          shouldUpdateInputValues={shouldUpdatePrice}
          minimumValidation={minimumValidation}
          shouldFocusInput
        />
      </div>
      {minimumValidation && (
        <div className="u-ui-padding-horizontal-medium u-ui-padding-bottom-regular">
          <Text
            type={Text.Type.Caption}
            theme="muted"
            as="span"
            text={translate('filters.price.designer_price_message', {
              value: formatCurrency(minimumValidation.amount, { locale, currency }),
            })}
          />
        </div>
      )}
    </FilterModal>
  )

  const formatCurrencyValue = (value: string) => {
    const numericValue = parseFloat(value)

    return formatNumber(numericValue, { style: 'currency', currency })
  }

  const getSelectedPriceRangeText = () => {
    const { priceFrom, priceTo } = selectedPriceRange

    if (priceFrom && priceTo) {
      return translate('selected_filters.both_price', {
        priceFrom: formatCurrencyValue(priceFrom),
        priceTo: formatCurrencyValue(priceTo),
      })
    }
    if (priceFrom) {
      return translate('selected_filters.price_from', { value: formatCurrencyValue(priceFrom) })
    }
    if (priceTo) {
      return translate('selected_filters.price_to', { value: formatCurrencyValue(priceTo) })
    }

    return null
  }

  const suffixText = getSelectedPriceRangeText()

  useEffect(() => {
    const { priceFrom, priceTo } = selectedPriceRange

    if (minimumValidation?.amount) {
      const minAmount = parseFloat(minimumValidation.amount)
      let priceFromValue = priceFrom
      let priceToValue = priceTo

      if (priceFrom && parseFloat(priceFrom) < minAmount) {
        if (priceTo && parseFloat(priceTo) >= minAmount) {
          priceFromValue = minimumValidation.amount.toString()
        } else {
          priceFromValue = null
        }
      }

      if (priceTo && parseFloat(priceTo) < minAmount) {
        priceToValue = null
      }

      const priceRange = {
        priceFrom: priceFromValue,
        priceTo: priceToValue,
      }

      handlePriceRangeChange(priceRange)
    }
    // to avoid endless loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <FilterCell
        title={translate('filters.price.name')}
        suffixText={suffixText || translate('filters.price.all')}
        suffixTextTheme={suffixText ? 'primary' : undefined}
        onClick={handleFilterCellClick}
        onView={onFilterCellView}
        visible={isFilterCellVisible}
      />
      {renderPriceFilterModal()}
    </>
  )
}

export default PriceFilter
