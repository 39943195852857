'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, EmptyState, Animation, Loader } from '@vinted/web-ui'
import classNames from 'classnames'

import useAsset from 'hooks/useAsset'
import useBreakpoint from 'hooks/useBreakpoint'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { CatalogItemDto } from 'types/dtos'
import { GridItemRenderProps, ItemVisibleCallbackArgs, GridItem } from 'types/components'
import { ClosetModel, ListerActivationBannerModel } from 'types/models'

import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { UiState } from 'constants/ui'

import { addClickListItemTracking } from 'containers/TrackingProvider'
import { impressionEvent, clickEvent, favouriteItemEvent } from 'libs/common/event-tracker/events'
import {
  buildGridItems,
  closetToItem,
  itemDtoToItem,
  adIndexToItem,
  GenerateGridItem,
  listerActivationBannerToItem,
  GridItemInsert,
} from 'data/utils/grid'
import { getRandomizedListerActivationBanner } from 'data/utils/banner'

import { AdShape } from 'constants/ads'
import * as bannersStatelessActions from 'state/banners/actions'

import {
  getCatalogItems,
  getCatalogItemUiState,
  getCatalogItemOffset,
  getCatalogItemErrors,
} from 'state/items/selectors'
import { getUser } from 'state/session/selectors'
import { retryCatalogItemsRequest } from 'state/items/actions'
import {
  getSearchCorrelationId,
  getSearchSessionId,
  getCatalogContentSource,
  getGlobalSearchSessionId,
  getCatalogUiState,
} from 'state/catalog-filters/selectors'
import { getAvailableListerBannersBySelectedCatalogs } from 'state/selectors'
import { dismissListerActivationBanners } from 'data/api'

import ItemGrid from 'components/ItemGrid'
import { ClosetPromotion } from 'components/ClosetPromotion'
import InfoBanner from 'components/InfoBanner'
import ListerActivationBanner from 'components/ListerActivationBanner'
import Advertisement from 'components/Advertisement'
import { ProductItem } from 'components/ProductItem'
import { getFirstListedBreakpoint } from 'components/Breakpoint'
import { transformCatalogItemDtoToProductItem } from 'data/transformers/product-item'
import { urlWithParams } from 'libs/utils/url'

import { getPlacementByShape } from 'state/ads/selectors'

import { CatalogFeature, catalogInsertPositions } from '../constants'
import { logCatalogError } from '../utils/log'

import useClosetPromotion from '../hooks/useClosetPromotion'

const LISTER_ACTIVATION_BREAKPOINT_ITEM_COUNT = {
  phones: 1,
  wide: 4,
  default: 3,
}

const TrackedProductItem = addClickListItemTracking(ProductItem)

type CatalogItemInsert = GridItemInsert<
  ListerActivationBannerModel | ClosetModel | Record<string, unknown>,
  string,
  Record<
    string,
    Array<
      GridItem<
        ListerActivationBannerModel | ClosetModel | Partial<ComponentProps<typeof Advertisement>>
      >
    >
  >
>

const handleItemsErrorLogging = (err: Error | null) => {
  logCatalogError(err, CatalogFeature.Items)
}

const resolveItemWidth = (
  item: GridItem<CatalogItemDto | ClosetModel | Record<string, unknown>>,
) => {
  if (!item.type) return null
  if (['closet_promotion', 'ad', 'lister_activation_banner'].includes(item.type)) return 'full-row'

  return null
}

const Items = () => {
  const asset = useAsset('/assets/animations')
  const breakpoints = useBreakpoint()
  const dispatch = useDispatch()
  const translate = useTranslate('catalog')
  const { track } = useTracking()

  const currentUser = useSelector(getUser)
  const adPlacement = useSelector(getPlacementByShape(AdShape.Inbetween))
  const items = useSelector(getCatalogItems)
  const errors = useSelector(getCatalogItemErrors)
  const uiState = useSelector(getCatalogItemUiState)
  const catalogUiState = useSelector(getCatalogUiState)
  const itemOffset = useSelector(getCatalogItemOffset)
  const searchCorrelationId = useSelector(getSearchCorrelationId)
  const searchSessionId = useSelector(getSearchSessionId)
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const contentSource = useSelector(getCatalogContentSource)
  const availableListerBannersBySelectedCatalogs = useSelector(
    getAvailableListerBannersBySelectedCatalogs,
  )

  const [showlisterActivationBanners, setShowlisterActivationBanners] = useState(true)

  const seenItemIds = useRef<Array<number>>([])
  const seenAds = useRef<Array<string | number>>([])
  const seenBanners = useRef<Array<string | number>>([])
  const gridBanners = useRef<Array<GridItem<ListerActivationBannerModel>>>([])

  const { closetsWithBanner, closetPromoBanner, handleClosetPromoVisibility } = useClosetPromotion()

  useEffect(() => {
    dispatch(bannersStatelessActions.fetchBannersRequest())
  }, [dispatch])

  useEffect(() => {
    gridBanners.current = []
  }, [items])

  const getAdContentSource = () => {
    return contentSource === ContentSource.Search ? ContentSource.AdSearch : ContentSource.AdCatalog
  }

  const handleAdVisbility = (id: string | number, position: number) => {
    if (seenAds.current.includes(id)) return

    track(
      impressionEvent({
        id,
        position,
        contentType: ListItemContentType.Ad,
        contentSource: getAdContentSource(),
        searchSessionId,
        searchCorrelationId,
        globalSearchSessionId,
      }),
    )

    seenAds.current.push(id)
  }

  const handleItemVisibility = (item: CatalogItemDto, position: number) => {
    const { id, user, content_source: itemContentSource } = item
    const { id: itemOwnerId } = user

    if (seenItemIds.current.includes(id)) return

    track(
      impressionEvent({
        id,
        position,
        contentType: ListItemContentType.Item,
        contentSource: itemContentSource,
        itemOwnerId,
        searchCorrelationId,
        searchSessionId,
        globalSearchSessionId,
        searchScore: item.search_tracking_params?.score,
        searchSignals: item.search_tracking_params?.matched_queries,
        itemDistance: item.search_tracking_params?.buyer_item_distance_km,
      }),
    )

    seenItemIds.current.push(id)
  }

  const handleBannerVisibility = (
    id: string | number,
    banner: ListerActivationBannerModel,
    position: number,
  ) => {
    if (seenBanners.current.includes(id)) return

    track(
      impressionEvent({
        id: banner.catalogId,
        position,
        contentType: ListItemContentType.ListerActivationBanner,
        contentSource,
      }),
    )

    seenBanners.current.push(id)
  }

  const handleFavouriteToggle = useCallback(
    (itemContentSource: ContentSource) =>
      ({ itemId, isFollowEvent }: { itemId: number; isFollowEvent: boolean }) => {
        track(clickEvent({ target: ClickableElement.Favourite }))
        track(
          favouriteItemEvent({
            itemId,
            isFollowEvent,
            contentSource: itemContentSource,
            globalSearchSessionId,
            searchSessionId,
            searchCorrelationId,
          }),
        )
      },
    [globalSearchSessionId, searchCorrelationId, searchSessionId, track],
  )

  const handleGridItemVisibility = (
    gridItem: ItemVisibleCallbackArgs<CatalogItemDto | ClosetModel | Record<string, unknown>>,
  ) => {
    const {
      item: { data, type, id },
      index: itemIndex,
    } = gridItem
    const position = itemOffset + itemIndex + 1

    switch (type) {
      case 'item':
        handleItemVisibility(data as CatalogItemDto, position)
        break
      case 'closet_promotion':
        handleClosetPromoVisibility(data as ClosetModel, position)
        break
      case 'lister_activation_banner':
        handleBannerVisibility(id, data as ListerActivationBannerModel, position)
        break
      case 'ad':
        handleAdVisbility(id, position)
        break
      default:
        break
    }
  }

  const handleBannerDismiss = () => {
    setShowlisterActivationBanners(false)

    dismissListerActivationBanners()
  }

  const generateListerActivationBannerItem: GenerateGridItem<ListerActivationBannerModel> = ({
    itemIndex,
  }) => {
    if (gridBanners.current[itemIndex]) return gridBanners.current[itemIndex]

    const banner = getRandomizedListerActivationBanner(availableListerBannersBySelectedCatalogs)
    const gridBanner = listerActivationBannerToItem(itemIndex, banner)

    gridBanners.current[itemIndex] = gridBanner

    return gridBanner
  }

  const getItemInserts = (): Array<CatalogItemInsert> => {
    const itemInserts: Array<CatalogItemInsert> = []

    const breakpoint = getFirstListedBreakpoint(breakpoints.active) || 'phones'
    const isAdsBreakpoint = breakpoint === 'phones'

    const closetPromoInsertPositions = catalogInsertPositions.closetPromo[breakpoint]
    const activationBannerInsertPositions = catalogInsertPositions.activationBanner[breakpoint]
    const adsInsertPositions = catalogInsertPositions.ads[breakpoint]

    if (showlisterActivationBanners && availableListerBannersBySelectedCatalogs.length) {
      itemInserts.push({
        items: {
          banners: [],
        },
        positions: activationBannerInsertPositions,
        generateItem: generateListerActivationBannerItem,
      })
    }

    const closetItems = closetsWithBanner.map(closetToItem)

    if (closetItems.length) {
      itemInserts.push({
        items: {
          closet_promotions: closetItems,
        },
        positions: closetPromoInsertPositions,
      })
    }

    if (adsInsertPositions && adPlacement && isAdsBreakpoint) {
      const adsItems = Array.from({ length: 4 }, (_, idx) => adIndexToItem(idx))

      itemInserts.push({
        items: {
          ads: adsItems,
        },
        positions: adsInsertPositions,
      })
    }

    return itemInserts
  }

  const renderItem = useCallback(
    (item: CatalogItemDto, position: number) => {
      const url = urlWithParams(item.url, {
        referrer: Screen.Catalog,
      })

      return (
        <TrackedProductItem
          item={transformCatalogItemDtoToProductItem(item)}
          viewingSelf={currentUser?.id === item.user.id}
          tracking={{
            id: item.id,
            contentType: ListItemContentType.Item,
            contentSource: item.content_source,
            position,
            searchCorrelationId,
            searchSessionId,
            globalSearchSessionId,
          }}
          onFavouriteToggle={handleFavouriteToggle(item.content_source)}
          url={url}
        />
      )
    },
    [
      currentUser?.id,
      globalSearchSessionId,
      handleFavouriteToggle,
      searchCorrelationId,
      searchSessionId,
    ],
  )

  const renderClosetPromotion = useCallback(
    (closet: ClosetModel, position: number) => {
      return (
        <ClosetPromotion
          closet={closet}
          wide={false}
          banner={closetPromoBanner}
          isWhiteLayout={false}
          position={position}
          contentSource={contentSource}
        />
      )
    },
    [contentSource, closetPromoBanner],
  )

  const renderListerActivationBanner = useCallback(
    (banner: ListerActivationBannerModel) => (
      <ListerActivationBanner
        breakpointItemCount={LISTER_ACTIVATION_BREAKPOINT_ITEM_COUNT}
        banner={banner}
        onClose={handleBannerDismiss}
      />
    ),
    [],
  )

  const getGridItems = () => {
    const gridItems = items.map(itemDtoToItem)

    const itemInserts = getItemInserts()

    return buildGridItems(gridItems, itemInserts)
  }

  const renderGridItem = useCallback(
    (props: GridItemRenderProps<CatalogItemDto | ClosetModel | Record<string, unknown>>) => {
      const { item, index } = props
      const position = itemOffset + index + 1

      switch (item.type) {
        case 'item':
          return renderItem(item.data as CatalogItemDto, position)
        case 'closet_promotion':
          return renderClosetPromotion(item.data as ClosetModel, position)
        case 'ad':
          return (
            <Advertisement
              id={String(item.id)}
              shape={AdShape.Inbetween}
              {...(item.data as Partial<ComponentProps<typeof Advertisement>>)}
            />
          )
        case 'lister_activation_banner':
          return renderListerActivationBanner(item.data as ListerActivationBannerModel)
        default:
          return null
      }
    },
    [itemOffset, renderClosetPromotion, renderItem, renderListerActivationBanner],
  )

  const renderError = (isLoading: boolean) => {
    return (
      <EmptyState
        title={translate('generic_error.title')}
        body={translate('generic_error.body')}
        action={
          <Button
            isLoading={isLoading}
            text={translate('generic_error.cta')}
            styling={Button.Styling.Filled}
            onClick={() => dispatch(retryCatalogItemsRequest())}
          />
        }
      />
    )
  }

  const renderEmpty = () => {
    if (items.length) return null

    return (
      <EmptyState
        animation={<Animation animationUrl={asset('search-empty-state.json')} />}
        title={translate('empty_state.title')}
        body={translate('empty_state.body')}
        testId="search-empty-state"
      />
    )
  }

  const renderInfoBanner = () => {
    const target = ClickableElement.MergeCatalogBanner

    return <InfoBanner screen={Screen.Catalog} linkTracking={{ target }} />
  }

  const isError = errors || catalogUiState === UiState.Failure
  const isLoading = uiState === UiState.Pending || catalogUiState === UiState.Pending
  const isLoadingWithNoItems =
    catalogUiState === UiState.Success && uiState === UiState.Pending && items.length === 0

  if (isError) return renderError(isLoading)
  if (isLoadingWithNoItems) {
    return (
      <div className="u-flexbox u-justify-content-center">
        <Loader testId="catalog-page-items-loader" />
      </div>
    )
  }

  const loaderClass = classNames({
    ajax: isLoading,
  })

  const gridItems = getGridItems()

  return (
    <div className={loaderClass}>
      {renderInfoBanner()}
      {renderEmpty()}
      <ItemGrid
        items={gridItems}
        renderItem={renderGridItem}
        onItemVisible={handleGridItemVisibility}
        widthResolver={resolveItemWidth}
        handleErrorLogging={handleItemsErrorLogging}
        preventLog
      />
    </div>
  )
}

export default Items
