'use client'

import { Chip, Text } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'

import { DynamicFilterOptionModel } from 'types/models/dynamic-filter'

type Props = {
  option: DynamicFilterOptionModel
  activated: boolean
  onClick: (option: DynamicFilterOptionModel) => void
  onInView?: (option: DynamicFilterOptionModel) => void
}

const GridOption = ({ activated, option, onClick, onInView }: Props) => {
  const { ref } = useInView({
    onChange: inView => {
      if (!inView) return
      onInView?.(option)
    },
  })

  const renderText = () => {
    const itemsCount = Number(option.itemsCount)
    const itemsCountMax = Number(option.itemsCountMax)

    if (!itemsCount || !itemsCountMax)
      return <Text theme="amplified" type={Text.Type.Subtitle} text={option.title} />

    const facet = `(${Math.min(itemsCount, itemsCountMax)}${itemsCount > itemsCountMax ? '+' : ''})`

    return (
      <Text theme="amplified" type={Text.Type.Subtitle}>
        {option.title} <Text as="span" type={Text.Type.Caption} text={facet} />
      </Text>
    )
  }

  const handleClick = () => onClick(option)

  return (
    <Chip
      ref={ref}
      testId={`chip-option-${option.id}`}
      onClick={handleClick}
      text={renderText()}
      activated={activated}
    />
  )
}

export default GridOption
