type Props = {
  code: string
  testId?: string
}

const ColorBubble = ({ code, testId }: Props) => (
  <div
    className={`color-select__value color-select__value--${code.toLowerCase()}`}
    data-testid={testId}
  />
)

export default ColorBubble
