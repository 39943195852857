'use client'

import { useState } from 'react'
import { Cell, Text, Divider, Icon } from '@vinted/web-ui'
import { ArrowLeft24 } from '@vinted/monochrome-icons'

import FilterCell from 'components/FilterCell'
import FilterModal from 'components/FilterModal'
import FilterModalNavigation from 'components/FilterModalNavigation'
import SelectableItemList from 'components/SelectableItemList'
import useTranslate from 'hooks/useTranslate'
import { SortByOption } from 'constants/filter'
import { SelectableListItem } from 'types/components'

type Props = {
  learnMoreUrl: string | null
  selectedOption: SortByOption | null | undefined
  isFilterCellVisible: boolean
  onOptionClick: (item: SortByOption) => void
  onSubmit: () => void
  onFilterCellClick: () => void
  onFilterCellView: () => void
  onModalOpen: () => void
  onModalClose: () => void
}

const SortFilter = ({
  learnMoreUrl,
  selectedOption,
  isFilterCellVisible,
  onOptionClick,
  onSubmit,
  onFilterCellClick,
  onFilterCellView,
  onModalOpen,
  onModalClose,
}: Props) => {
  const translate = useTranslate('catalog.filters')

  const [isModalOpen, setIsModalOpen] = useState(false)

  const shownOption = selectedOption || SortByOption.Relevance

  const closeModal = () => {
    setIsModalOpen(false)
    onModalClose()
  }

  const openModal = () => {
    setIsModalOpen(true)
    onModalOpen()
  }

  const handleOptionClick = (item: SelectableListItem<SortByOption, SortByOption>) => {
    closeModal()
    onOptionClick(item.value)
  }

  const handleSubmitClick = () => {
    closeModal()
    onSubmit()
  }

  const handleFilterCellClick = () => {
    openModal()
    onFilterCellClick()
  }

  const renderModalNavigation = () => (
    <FilterModalNavigation
      title={translate('sort.name')}
      icon={<Icon name={ArrowLeft24} testId="navigation-prefix-icon" />}
      prefixAriaLabel={translate('a11y.actions.back')}
      onPrefixClick={closeModal}
    />
  )

  const renderLearnMoreMessage = () => {
    if (!learnMoreUrl) return null

    const action = <a href={learnMoreUrl}>{translate('sort.learn_more.action')}</a>

    return (
      <>
        <Divider />
        <Cell testId="learn-more-message">
          <Text type={Text.Type.Caption} text={translate('sort.learn_more.message', { action })} />
        </Cell>
      </>
    )
  }

  const renderSortFilterModal = () => {
    const items = Object.values(SortByOption).map(option => ({
      id: option,
      title: translate(`sort.options.${option}`),
      value: option,
    }))

    return (
      <FilterModal
        renderNavigation={renderModalNavigation}
        isOpen={isModalOpen}
        onSubmit={handleSubmitClick}
        testId="sort-modal"
      >
        <SelectableItemList<SortByOption, SortByOption>
          name="sort_by"
          isMultiSelect={false}
          items={items}
          selected={[shownOption]}
          onItemClick={handleOptionClick}
          disableButtonClicks
        />
        {renderLearnMoreMessage()}
      </FilterModal>
    )
  }

  return (
    <>
      <FilterCell
        title={translate('sort.name')}
        suffixText={translate(`sort.options.${shownOption}`)}
        suffixTextTheme="primary"
        onClick={handleFilterCellClick}
        onView={onFilterCellView}
        visible={isFilterCellVisible}
      />
      {renderSortFilterModal()}
    </>
  )
}

export default SortFilter
