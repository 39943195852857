'use client'

import { ReactNode, useCallback, useState } from 'react'
import { Cell, Image, Divider, Icon } from '@vinted/web-ui'
import { Dots24, ChevronRight24, ArrowLeft24 } from '@vinted/monochrome-icons'
import { compact } from 'lodash'

import { ROOT_CATALOG_ID, WEB_CATALOG_ROOT_ALL_CODE, CatalogAttribute } from 'constants/catalog'
import { Screen } from 'constants/tracking/screens'
import FilterCell from 'components/FilterCell'
import FilterModal from 'components/FilterModal'
import FilterModalNavigation from 'components/FilterModalNavigation'
import FilterCellSuffix from 'components/FilterCell/FilterCellSuffix'
import SelectableItemList, {
  RenderItemProps,
} from 'components/SelectableItemList/SelectableItemList'
import { getCatalogPhotoSrc } from 'data/utils/catalog'
import { selectCatalogEvent } from 'libs/common/event-tracker/events'
import useCatalogFilter from 'pages/Catalog/hooks/useCatalogFilter'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import { CatalogModel } from 'types/models/catalog'
import { CatalogItem, CatalogItemData } from 'types/catalog-filters'

type Props = {
  allCatalogs: Record<number, CatalogModel>
  selectedCatalogsIds: Array<number>
  isFilterCellVisible: boolean
  onCatalogSelect: (newCatalogIds: Array<number>) => void
  onSubmit: () => void
  onFilterCellClick: () => void
  onFilterCellView: () => void
  onModalOpen: () => void
  onModalClose: () => void
}

const CatalogFilter = ({
  allCatalogs,
  selectedCatalogsIds,
  isFilterCellVisible,
  onCatalogSelect,
  onSubmit,
  onFilterCellClick,
  onFilterCellView,
  onModalOpen,
  onModalClose,
}: Props) => {
  const translate = useTranslate('catalog.filters')
  const { track } = useTracking()
  const [openedCatalogsIds, setOpenedCatalogsIds] = useState<Array<number>>([])

  const { allShownCatalogs } = useCatalogFilter(
    openedCatalogsIds,
    translate('catalog.all'),
    allCatalogs,
  )

  const getParentIds = useCallback(
    (catalog: CatalogItemData, ids: Array<number> = []) => {
      const parent = allCatalogs[catalog.parentId]

      if (!parent) return ids

      return getParentIds(parent, [...ids, parent.id])
    },
    [allCatalogs],
  )

  const closeModal = (catalogId: number) => {
    setOpenedCatalogsIds(prevIds => prevIds.filter(prevId => prevId !== catalogId))
  }

  const openModal = (catalogId: number) => {
    setOpenedCatalogsIds(prevIds => [...prevIds, catalogId])
  }

  const closeAllModals = () => {
    setOpenedCatalogsIds([])
    onModalClose()
  }

  const handleModalClose = (catalogId: number) => () => {
    if (catalogId === ROOT_CATALOG_ID) onModalClose()
    closeModal(catalogId)
  }

  const handleSubmitClick = () => {
    closeAllModals()
    onSubmit()
  }

  const clearSelectedCatalogs = (catalogId: number) => () => {
    let newSelectedIds: Array<number> = []

    if (catalogId !== ROOT_CATALOG_ID) {
      newSelectedIds = selectedCatalogsIds.filter(
        setId => ![setId, ...getParentIds(allCatalogs[setId])].includes(catalogId),
      )
    }

    onCatalogSelect(newSelectedIds)
  }

  const trackCatalogSelection = (selectedCatalogIds: Array<number>) => {
    track(
      selectCatalogEvent({
        catalogIds: selectedCatalogIds,
        attributeId: CatalogAttribute.Filter,
        screen: Screen.MobileCatalogFilter,
      }),
    )
  }

  const handleCatalogClick = (catalog: CatalogItem) => {
    if (!catalog.data) return

    const { id: catalogId, catalogIds, code } = catalog.data

    if (catalogIds.length > 0 && code !== WEB_CATALOG_ROOT_ALL_CODE) {
      openModal(catalogId)

      return
    }

    let newSelectedIds: Array<number> = []

    if (selectedCatalogsIds.includes(catalogId)) {
      newSelectedIds = selectedCatalogsIds.filter(setId => setId !== catalogId)
    } else {
      newSelectedIds = [catalogId]
    }

    trackCatalogSelection(newSelectedIds)
    onCatalogSelect(newSelectedIds)

    closeAllModals()
  }

  const handleFilterCellClick = () => {
    openModal(ROOT_CATALOG_ID)
    onFilterCellClick()
    onModalOpen()
  }

  const renderIcon = (photo: CatalogModel['photo'], code: string, depth: number) => {
    if (depth > 1) return null
    if (code === WEB_CATALOG_ROOT_ALL_CODE) {
      return <Icon name={Dots24} color={Icon.Color.Primary} />
    }
    if (!photo) return null

    return <Image {...getCatalogPhotoSrc(photo)} size={Image.Size.Regular} />
  }

  const renderCatalogSuffix = (isNavigation: boolean, suffix: ReactNode) => () => {
    if (isNavigation) return <Icon name={ChevronRight24} color={Icon.Color.GreyscaleLevel3} />

    return suffix
  }

  const getCatalogSuffixText = (catalogId: number) => {
    let selectedChildrenIds: Array<number> = selectedCatalogsIds

    if (catalogId !== ROOT_CATALOG_ID) {
      selectedChildrenIds = selectedCatalogsIds.filter(setId =>
        [setId, ...getParentIds(allCatalogs[setId])].includes(catalogId),
      )
    }

    return compact(selectedChildrenIds.map(id => allCatalogs[id]?.title)).join(', ')
  }

  const renderCatalog = ({ item, itemElementProps }: RenderItemProps<CatalogItemData>) => {
    if (!item.data) return null

    const { id, catalogIds, code, depth, photo } = item.data
    const isNavigation = catalogIds.length > 0 && code !== WEB_CATALOG_ROOT_ALL_CODE

    return (
      <Cell
        {...itemElementProps}
        prefix={renderIcon(photo, code, depth)}
        suffix={
          <FilterCellSuffix
            suffixText={isNavigation && getCatalogSuffixText(id)}
            textTheme="primary"
            renderSuffix={renderCatalogSuffix(isNavigation, itemElementProps.suffix)}
          />
        }
      />
    )
  }

  const renderModalNavigation = (catalogId: number, title: string) => () => (
    <FilterModalNavigation
      title={title}
      icon={<Icon name={ArrowLeft24} testId={`${title}-navigation-prefix-icon`} />}
      prefixAriaLabel={translate('a11y.actions.back')}
      onPrefixClick={handleModalClose(catalogId)}
      onSuffixClick={clearSelectedCatalogs(catalogId)}
    />
  )

  const renderCatalogFilterModal = (catalogId: number) => {
    const shownCatalogs = allShownCatalogs[catalogId]

    if (!shownCatalogs?.length) return null

    const title =
      catalogId === ROOT_CATALOG_ID ? translate('catalog.name') : allCatalogs[catalogId].title

    return (
      <FilterModal
        renderNavigation={renderModalNavigation(catalogId, title)}
        onSubmit={handleSubmitClick}
        isOpen
        key={catalogId}
        hideSubmitButton
        testId={`${title}-catalog-modal`}
      >
        <SelectableItemList<CatalogItemData, number>
          name={`${title}_catalog_ids`}
          items={shownCatalogs}
          selected={selectedCatalogsIds}
          renderItem={renderCatalog}
          onItemClick={handleCatalogClick}
          isMultiSelect={false}
          disableButtonClicks
        />
        <Divider />
      </FilterModal>
    )
  }

  const suffixText = getCatalogSuffixText(ROOT_CATALOG_ID)

  return (
    <>
      <FilterCell
        title={translate('catalog.name')}
        suffixText={suffixText || translate('catalog.all')}
        suffixTextTheme={suffixText ? 'primary' : undefined}
        onClick={handleFilterCellClick}
        onView={onFilterCellView}
        visible={isFilterCellVisible}
      />
      {openedCatalogsIds.map(renderCatalogFilterModal)}
    </>
  )
}

export default CatalogFilter
