'use client'

import { ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Cell, Image, Modal, Spacer, Text } from '@vinted/web-ui'

import SeparatedList from 'components/SeparatedList'
import { UiState } from 'constants/ui'
import { ReportReasonCatalogSystem } from 'constants/report-reason'
import { checkIsAlertsGeneratorBusy, generateAlertsFromSearchParams } from 'data/api'
import useAsset from 'hooks/useAsset'
import { toParams } from 'libs/utils/url'
import { getCatalogItemTotalEntries } from 'state/items/selectors'

import ReportReasonList from './ReportReasonList'

const MAX_SEARCH_COUNT = 500

const Title = ({ title }: { title: string }) => (
  <Text
    type={Text.Type.Heading}
    text={title}
    width={Text.Width.Parent}
    alignment={Text.Alignment.Center}
  />
)

const IconImage = ({ name }: { name: string }) => {
  const asset = useAsset()

  return (
    <div className="u-flexbox u-justify-content-center">
      <Image
        src={asset(`${name}.svg`, { theme: { dark: `${name}-dark.svg` } })}
        size={Image.Size.Large}
      />
    </div>
  )
}

const CloseButton = ({ text, onClose }: { onClose: () => void; text: string }) => (
  <Button text={text} onClick={onClose} styling={Button.Styling.Flat} />
)

const ModalSection = ({ children }: { children: Array<ReactNode> | ReactNode }) => {
  if (Array.isArray(children)) {
    return (
      <Cell>
        <SeparatedList separator={<Spacer size={Spacer.Size.Large} />}>{children}</SeparatedList>
      </Cell>
    )
  }

  return <Cell>{children}</Cell>
}

const SendToAlerts = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalUiState, setModalUiState] = useState(UiState.Idle)
  const [sendUiState, setSendUiState] = useState(UiState.Idle)
  const [selectedReportReason, setSelectedReportReason] = useState<ReportReasonCatalogSystem>()
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false)

  const totalEntries = useSelector(getCatalogItemTotalEntries) || 0

  const isCountOutOfLimits = totalEntries > MAX_SEARCH_COUNT

  const checkAlertsGenerationStatus = async () => {
    setModalUiState(UiState.Pending)

    const response = await checkIsAlertsGeneratorBusy()

    if ('errors' in response || response.is_busy) {
      setModalUiState(UiState.Failure)

      return
    }

    setModalUiState(UiState.Success)
  }

  const handleOpenModal = async () => {
    if (isCountOutOfLimits) {
      setIsOpen(true)

      return
    }

    await checkAlertsGenerationStatus()

    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setSendUiState(UiState.Idle)
    setSelectedReportReason(undefined)
    setIsSubmitAttempted(false)
  }

  const handleSendToAlerts = async () => {
    // window API is called to catch the url query changes
    // other methods as useLocation() or useRouter()
    // can't catch this changes because sagas is directly
    // using window API history `pushState`/`replaceState`

    const searchParams = toParams(window.location.search)

    setIsSubmitAttempted(true)

    if (!selectedReportReason) return

    setSendUiState(UiState.Pending)

    const response = await generateAlertsFromSearchParams({
      searchParams,
      reportReason: selectedReportReason,
    })

    if ('errors' in response) {
      setSendUiState(UiState.Failure)

      return
    }

    setSendUiState(UiState.Success)
    setSelectedReportReason(undefined)
  }

  const handleReportReasonSelect = (value: ReportReasonCatalogSystem) => {
    setSelectedReportReason(value)
  }

  const renderListingsLimitError = () => (
    <>
      <ModalSection>
        <Title title={`${MAX_SEARCH_COUNT} alerts limit`} />
        <Text
          text={`You can’t send more than ${MAX_SEARCH_COUNT} alerts at once. Adjust your filters to refine search results.`}
        />
      </ModalSection>
      <ModalSection>
        <CloseButton onClose={handleCloseModal} text="Close" />
      </ModalSection>
    </>
  )

  const renderSendAlertsPrompt = () => (
    <>
      <ModalSection>
        <Title title="Create and send alerts?" />
        <Text
          text={`You can send ${MAX_SEARCH_COUNT} alerts at once. If you want to send more, wait a couple of minutes and repeat actions.`}
        />
        <Text text="Creating and sending alerts can take some time. You won't be able to send new ones immediately" />
      </ModalSection>
      <ReportReasonList
        selectedValue={selectedReportReason}
        onSelect={handleReportReasonSelect}
        isSubmitAttempted={isSubmitAttempted}
      />
      <ModalSection>
        <Button
          text="Send"
          onClick={handleSendToAlerts}
          styling={Button.Styling.Filled}
          isLoading={sendUiState === UiState.Pending}
        />
        <CloseButton onClose={handleCloseModal} text="Close" />
      </ModalSection>
    </>
  )

  const renderAlertsSent = () => (
    <>
      <ModalSection>
        <IconImage name="check-circle" />
        <Title title="Listings were sent" />
        <Text text="The system has started to work on generating alerts. It will take some time to create all alerts" />
      </ModalSection>
      <ModalSection>
        <CloseButton onClose={handleCloseModal} text="Close" />
      </ModalSection>
    </>
  )

  const renderGenerationPendingError = () => (
    <>
      <ModalSection>
        <IconImage name="clock" />
        <Title title="System is already generating alerts" />
        <Text text="Please wait till all alerts are generated and try again" />
      </ModalSection>
      <ModalSection>
        <CloseButton onClose={handleCloseModal} text="Close" />
      </ModalSection>
    </>
  )

  const renderUnknownError = () => (
    <>
      <ModalSection>
        <Title title="Listings weren’t sent to alerts" />
        <Text text="An unknown error occurred while trying to send listings to alerts. Please try again" />
      </ModalSection>
      <ModalSection>
        <CloseButton onClose={handleCloseModal} text="Close" />
      </ModalSection>
    </>
  )

  const renderModalContent = () => {
    if (isCountOutOfLimits) return renderListingsLimitError()

    if (modalUiState === UiState.Failure) return renderGenerationPendingError()

    if (sendUiState === UiState.Success) return renderAlertsSent()

    if (sendUiState === UiState.Failure) return renderUnknownError()

    return renderSendAlertsPrompt()
  }

  return (
    <>
      <Button
        text="Send all to alerts"
        onClick={handleOpenModal}
        size={Button.Size.Small}
        styling={Button.Styling.Filled}
        isLoading={modalUiState === UiState.Pending}
        inline
      />
      <Modal show={isOpen}>{renderModalContent()}</Modal>
    </>
  )
}

export default SendToAlerts
