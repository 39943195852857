'use client'

import { KeyboardEvent, useRef, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { InputText } from '@vinted/web-ui'

import { formatAmount, formatCurrency } from 'libs/utils/formatString'
import { KeyboardKey } from 'constants/keyboard'

import CurrencyMask from 'components/CurrencyMask'

import { State as AppState } from 'state/types'
import { getFilterCurrency, getCurrency } from 'state/catalog-filters/selectors'
import { PriceRange } from 'state/catalog-filters/types'
import { CurrencyAmountModel } from 'types/models'
import { getLocale } from 'state/intl/selectors'

const PRICE_MAX_LENGTH = 6

enum PriceRangeType {
  PriceFrom = 'priceFrom',
  PriceTo = 'priceTo',
}

type Props = {
  priceRange: PriceRange
  priceFromTitle: string | JSX.Element
  priceToTitle: string | JSX.Element
  shouldFocusInput?: boolean
  shouldUpdateInputValues?: boolean
  minimumValidation?: CurrencyAmountModel
  onPriceRangeChange: (priceRange: PriceRange, currency: string) => void
}

const PriceRangeInput = ({
  priceRange,
  priceFromTitle,
  priceToTitle,
  shouldFocusInput = false,
  shouldUpdateInputValues = false,
  minimumValidation,
  onPriceRangeChange,
}: Props) => {
  const locale = useSelector(getLocale)
  const currency = useSelector((state: AppState) => getFilterCurrency(state) || getCurrency(state))

  const priceFromInputRef = useRef<HTMLInputElement>(null)
  const priceToInputRef = useRef<HTMLInputElement>(null)
  const priceRangeRef = useRef(priceRange)

  useEffect(() => {
    priceRangeRef.current = priceRange
  }, [priceRange])

  const getInputRef = (input: PriceRangeType) => {
    if (input === PriceRangeType.PriceFrom) {
      return priceFromInputRef
    }

    return priceToInputRef
  }

  const focusInput = useCallback((inputName: PriceRangeType) => {
    const { current: inputNode } = getInputRef(inputName)

    if (!inputNode) return

    inputNode.focus()
  }, [])

  useEffect(() => {
    if (shouldFocusInput) focusInput(PriceRangeType.PriceFrom)
  }, [shouldFocusInput, focusInput])

  const getNewValue = (name: PriceRangeType, value: string | null | undefined) => {
    let newValue = formatAmount(value, { maxLength: PRICE_MAX_LENGTH })
    const minValue = minimumValidation ? parseFloat(minimumValidation.amount) : 0
    const enteredValue = value ? parseFloat(value) || 0 : 0

    if (name === PriceRangeType.PriceFrom && enteredValue < minValue) {
      newValue = minValue.toString()
    }

    if (name === PriceRangeType.PriceTo && enteredValue < minValue) {
      newValue = null
    }

    return newValue
  }

  const updatePriceRange = (name: PriceRangeType, value: string | null | undefined) => {
    const currentValue = priceRangeRef.current[name]
    const newValue = getNewValue(name, value)
    const currentFloatValue = typeof currentValue === 'string' ? parseFloat(currentValue) : null
    const newFloatValue = typeof newValue === 'string' ? parseFloat(newValue) : null

    if (currentFloatValue === null && newFloatValue === null) return
    if (currentFloatValue === newFloatValue) return

    priceRangeRef.current[name] = newValue

    onPriceRangeChange(priceRangeRef.current, currency)
  }

  const handleInputBlur =
    (name: PriceRangeType, blurCallback: () => void, value: string | null | undefined) => () => {
      blurCallback()
      updatePriceRange(name, value)
    }

  const handleInputKeyDown =
    (name: PriceRangeType, value: string | null | undefined) => (event: KeyboardEvent) => {
      if (event.key !== KeyboardKey.Enter) return

      const nextInputName =
        name === PriceRangeType.PriceFrom ? PriceRangeType.PriceTo : PriceRangeType.PriceFrom

      focusInput(nextInputName)
      updatePriceRange(name, value)
    }

  const fromPlaceholder = minimumValidation
    ? formatCurrency(minimumValidation.amount, { locale, currency })
    : formatCurrency(0, { locale, currency })

  const toPlaceholder = formatCurrency(0, { locale, currency }).replace(/[0-9.,\s]/g, '')

  return (
    <>
      <CurrencyMask
        value={priceRange.priceFrom}
        maxLength={PRICE_MAX_LENGTH}
        locale={locale}
        currency={currency}
        forceUpdateValue={shouldUpdateInputValues}
      >
        {({ handleValue, value, handleFocus, handleBlur }) => (
          <InputText
            title={priceFromTitle}
            name="price_from"
            placeholder={fromPlaceholder}
            value={value}
            onChange={handleValue}
            onFocus={handleFocus}
            onBlur={handleInputBlur(PriceRangeType.PriceFrom, handleBlur, value)}
            onKeyDown={handleInputKeyDown(PriceRangeType.PriceFrom, value)}
            ref={priceFromInputRef}
          />
        )}
      </CurrencyMask>

      <CurrencyMask
        value={priceRange.priceTo}
        maxLength={PRICE_MAX_LENGTH}
        locale={locale}
        currency={currency}
        forceUpdateValue={shouldUpdateInputValues}
      >
        {({ handleValue, value, handleFocus, handleBlur }) => (
          <InputText
            title={priceToTitle}
            name="price_to"
            placeholder={toPlaceholder}
            value={value}
            onChange={handleValue}
            onFocus={handleFocus}
            onBlur={handleInputBlur(PriceRangeType.PriceTo, handleBlur, value)}
            onKeyDown={handleInputKeyDown(PriceRangeType.PriceTo, value)}
            ref={priceToInputRef}
          />
        )}
      </CurrencyMask>
    </>
  )
}

export default PriceRangeInput
