import { Spacer } from '@vinted/web-ui'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const VerticalPadder = ({ children }: Props) => {
  return (
    <div className="u-flexbox">
      <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
      {children}
      <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
    </div>
  )
}

export default VerticalPadder
