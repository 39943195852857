'use client'

import { useSelector } from 'react-redux'

import { Screen } from 'constants/tracking/screens'
import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import { getSystemConfiguration } from 'state/system-configuration/selectors'
import { getCatalogsForBreadcrumbs, getSelectedCatalogs } from 'state/catalog-filters/selectors'
import { BRAND_ROUTE_REGEX } from 'state/catalog-filters/constants'
import { getBrandRequest } from 'data/api/requests'
import useLocation from 'hooks/useLocation'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import { BrandDto } from 'types/dtos'
import { transformBrandDto } from 'data/transformers'

import {
  transformBrandCatalogPageSchemaMarkup,
  transformBreadcrumbModelsFromCatalogModel,
} from '../../libs/seo/schema-markup/transformers'

import { getLayoutServerSideProps } from '../../layouts/FullPage/server-props'
import { moneyObjectHeader } from '../../constants/api'
import { handleSeoContent } from '../../libs/seo/content/server'
import { getBrandCatalogSeoParam } from '../../libs/seo/utils/params'
import PageHead from '../../components/Head/PageHead'
import fullPageLayout from '../../layouts/FullPage'
import Catalog from '../../../app/pages/Catalog'

type Props = {
  brand?: BrandDto
  isIndexed?: boolean
  isPaginated?: boolean
  seo?: {
    meta?: {
      title: string
      description: string
    }
  }
}

const CatalogPage = ({ brand, isIndexed, isPaginated, seo }: Props) => {
  const translate = useTranslate()
  const catalogs = useSelector(getSelectedCatalogs)
  const catalogsBreadcrumbs = useSelector(getCatalogsForBreadcrumbs)
  const systemConfig = useSelector(getSystemConfiguration)
  const { relativeUrl, baseUrl } = useLocation()
  const isSchemaMarkupEnabled = useSelector(
    getIsFeatureSwitchEnabled('schema_markup_brand_catalog_page'),
  )
  const asset = useAsset('/assets/web-logo/default')

  const transformedBrand = brand && transformBrandDto(brand)
  const learnMoreUrl = systemConfig?.urls?.relevancyDescription ?? null
  const brandTitle = transformedBrand?.title
  const title = seo?.meta?.title || brandTitle || catalogs[0]?.title || translate('catalog.items')
  const schemaMarkupBreadcrumbs = brandTitle
    ? [{ url: `${baseUrl}${relativeUrl}`, title: brandTitle }]
    : transformBreadcrumbModelsFromCatalogModel(catalogsBreadcrumbs, baseUrl)

  const brandCatalogPageSchemaMarkup = isSchemaMarkupEnabled
    ? transformBrandCatalogPageSchemaMarkup({
        meta_title: title,
        meta_description: translate('main.page_description'),
        baseUrl,
        breadcrumbs: schemaMarkupBreadcrumbs,
        logo: asset('logo.svg'),
      })
    : undefined

  return (
    <>
      <PageHead
        isIndexed={isIndexed}
        title={title}
        meta={{
          robots: isPaginated ? 'noindex, follow' : undefined,
        }}
        titleSuffix={seo?.meta?.title ? '' : undefined}
        jsonLd={brandCatalogPageSchemaMarkup}
      />
      <Catalog learnMoreUrl={learnMoreUrl} brand={transformedBrand} catalog={catalogs[0]} />
    </>
  )
}

export default fullPageLayout(CatalogPage)

export const getServerSideProps = getLayoutServerSideProps(
  async (context, serverProps) => {
    const { url } = context.req
    const isPaginated = 'page' in context.query

    const { featureSwitch, reqUrl } = getBrandCatalogSeoParam(url)

    const fetchIsBrandIndexed = async () => {
      const match = url?.match(BRAND_ROUTE_REGEX)
      const brandId = match?.[1]

      if (brandId) {
        const { brand } = await getBrandRequest(serverProps.api)(brandId, {
          headers: moneyObjectHeader,
          throwError: true,
        })

        return {
          isIndexed: brand?.is_visible_in_listings || false,
          brand,
        }
      }

      return {
        isIndexed: true,
        brand: undefined,
      }
    }

    const [{ isIndexed, brand }, meta] = await Promise.all([
      fetchIsBrandIndexed(),
      handleSeoContent(featureSwitch, reqUrl, serverProps),
    ])

    return {
      props: {
        brand,
        isIndexed,
        isPaginated,
        seo: {
          meta,
        },
      },
    }
  },
  {
    id: [
      {
        path: '^/brand/[^/]+$',
        id: 'brand',
      },
      {
        path: '^/catalog$',
        id: 'catalog',
      },
      {
        path: '^/catalog/[^/]+$',
        id: 'catalog-by-id',
      },
    ],
    screen: Screen.Catalog,
  },
)
