'use client'

import { ReactNode } from 'react'
import { Cell, Icon } from '@vinted/web-ui'
import { ChevronRight24 } from '@vinted/monochrome-icons'
import { InView } from 'react-intersection-observer'

import FilterCellSuffix from './FilterCellSuffix/FilterCellSuffix'

type Props = {
  title: ReactNode
  visible?: boolean
  suffixText?: ReactNode
  suffixTextTheme?: ComponentProps<typeof FilterCellSuffix>['textTheme']
  otherCellProps?: ComponentProps<typeof Cell>
  onClick: () => void
  onView?: () => void
  renderCustomSuffix?: () => ReactNode
}

const FilterCell = ({
  title,
  visible = true,
  suffixText,
  suffixTextTheme,
  otherCellProps,
  onClick,
  onView,
  renderCustomSuffix,
}: Props) => {
  const renderDefaultSuffix = () => (
    <Icon name={ChevronRight24} color={Icon.Color.GreyscaleLevel3} testId="default-suffix-icon" />
  )

  const renderSuffix = () => (
    <FilterCellSuffix
      suffixText={suffixText}
      renderSuffix={renderCustomSuffix || renderDefaultSuffix}
      textTheme={suffixTextTheme}
    />
  )

  const renderCell = () => (
    <Cell
      {...otherCellProps}
      title={title}
      suffix={renderSuffix ? renderSuffix() : renderDefaultSuffix()}
      type={Cell.Type.Navigating}
      onClick={onClick}
      clickable
    />
  )

  const handleInView = (inView: boolean) => {
    if (!inView) return

    onView?.()
  }

  if (visible) {
    return <InView onChange={handleInView}>{renderCell()}</InView>
  }

  return renderCell()
}

export default FilterCell
