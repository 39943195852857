'use client'

import { useSelector } from 'react-redux'
import { useState } from 'react'
import { Button, Icon, Spacer, Text } from '@vinted/web-ui'
import { QuestionCircle24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import { getCatalogItemTotalEntries } from 'state/items/selectors'

import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import SearchResultsRankingModal from 'pages/Catalog/SearchResultsRankingModal'

import ItemCount from '../ItemCount'

type Props = {
  learnMoreUrl: string | null
}

const ResultRanking = ({ learnMoreUrl }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('catalog.search_results')
  const totalEntries = useSelector(getCatalogItemTotalEntries)
  const [showModal, setShowModal] = useState(false)

  const handleResultRankingClick = () => {
    track(clickEvent({ target: ClickableElement.CatalogSearchResultsRanking }))
    setShowModal(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  if (!totalEntries) return null

  return (
    <div className="u-ui-padding-vertical-medium u-flexbox u-justify-content-between u-align-items-center">
      <ItemCount />
      {learnMoreUrl && (
        <>
          <Spacer orientation={Spacer.Orientation.Vertical} />
          <Button
            inline
            icon={<Icon name={QuestionCircle24} />}
            iconColor={Icon.Color.GreyscaleLevel3}
            iconPosition={Button.IconPosition.Right}
            onClick={handleResultRankingClick}
            styling={Button.Styling.Flat}
            testId="search_results_ranking_link"
            theme="muted"
          >
            <Text type={Text.Type.Subtitle} text={translate('learn_more_link')} as="span" />
          </Button>
          <SearchResultsRankingModal show={showModal} onConfirmOrClose={handleModalClose} />
        </>
      )}
    </div>
  )
}

export default ResultRanking
