'use client'

import { ReactNode } from 'react'
import { Text, Navigation, Button } from '@vinted/web-ui'

type Props = {
  title: ReactNode
  icon: ComponentProps<typeof Button>['icon']
  prefixAriaLabel?: string
  suffixText?: ReactNode
  testId?: string
  onPrefixClick: () => void
  onSuffixClick?: () => void
}

const FilterModalNavigation = ({
  title,
  icon,
  prefixAriaLabel,
  suffixText,
  testId,
  onPrefixClick,
  onSuffixClick,
}: Props) => {
  return (
    <Navigation
      left={
        <Button
          styling={Button.Styling.Flat}
          onClick={onPrefixClick}
          icon={icon}
          size={Button.Size.Medium}
          testId={testId ? `${testId}-prefix` : undefined}
          aria={{ 'aria-label': prefixAriaLabel }}
        />
      }
      body={<Text text={title} type={Text.Type.Title} />}
      right={
        !!suffixText && (
          <Button
            styling={Button.Styling.Flat}
            onClick={onSuffixClick}
            text={<Text text={suffixText} type={Text.Type.Title} />}
          />
        )
      }
      testId={testId}
    />
  )
}

export default FilterModalNavigation
