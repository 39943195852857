'use client'

import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Chip, Text, Icon } from '@vinted/web-ui'
import { X16 } from '@vinted/monochrome-icons'
import { isEmpty } from 'lodash'

import { flattenDynamicFilterOptions } from 'state/catalog-filters/utils'

import { FormattedMessageValues } from 'types/components'
import { IntlProps } from 'types/intl'

import { State as AppState } from 'state/types'
import * as selectors from 'state/catalog-filters/selectors'
import * as constants from 'state/catalog-filters/constants'
import { SelectedFilter } from 'state/catalog-filters/types'
import { DynamicFilterModel } from 'types/models/dynamic-filter'
import { actions } from 'state/catalog-filters/slice'
import { getCurrency } from 'state/catalog-filters/selectors'

import FilterReset from '../FilterReset'

const mapStateToProps = (state: AppState) => ({
  filters: selectors.getSelectedFilters(state),
  dynamicFilters: selectors.getDynamicFilters(state),
  selectedDynamicFilters: selectors.getSelectedDynamicFilters(state),
  currency: getCurrency(state),
})

const mapActionsToProps = {
  onFilterClick: actions.removeFilter,
  onDynamicFilterClick: actions.removeSelectedDynamicFilterId,
}

const connector = connect(mapStateToProps, mapActionsToProps)

type Props = ConnectedProps<typeof connector> & IntlProps

class SelectedFilters extends Component<Props> {
  static defaultProps = {
    filters: [],
    currency: 'EUR',
  }

  translate = (id: string, values?: FormattedMessageValues) => {
    const { intl } = this.props

    return intl.formatMessage({ id: `catalog.selected_filters.${id}` }, values)
  }

  formatLabel = (filter: SelectedFilter) => {
    const { name, label, value } = filter

    if (!isEmpty(label)) return label

    switch (name) {
      case constants.FILTER_PRICE_FROM:
        return this.translate('price_from', { value: this.formatCurrencyValue(value) })
      case constants.FILTER_PRICE_TO:
        return this.translate('price_to', { value: this.formatCurrencyValue(value) })
      default:
        return name
    }
  }

  formatCurrencyValue = (value: string | number) => {
    const { currency } = this.props
    const numericValue = parseFloat(String(value))

    return this.props.intl.formatNumber(numericValue, { style: 'currency', currency })
  }

  handleClick = (filter: SelectedFilter) => () => {
    const { onFilterClick, onDynamicFilterClick } = this.props

    return filter.isDynamic
      ? onDynamicFilterClick({ type: filter.name, id: Number(filter.value) })
      : onFilterClick({ filter })
  }

  renderFilter = (filter: SelectedFilter) => {
    const key = filter.isDynamic
      ? `${filter.name}Ids-${filter.value}`
      : `${filter.name}-${filter.value}`
    const text = this.formatLabel(filter)

    return (
      <div key={key} className="u-ui-margin-right-regular u-ui-margin-bottom-regular">
        <Chip
          text={text}
          textType={Text.Type.Subtitle}
          suffix={<Icon name={X16} />}
          styling={Chip.Styling.Filled}
          radius={Chip.Radius.Round}
          onClick={this.handleClick(filter)}
          testId={`catalog--selected-filter-${key}`}
        />
      </div>
    )
  }

  getSelectedFilters = () => {
    const { filters, selectedDynamicFilters, dynamicFilters } = this.props
    const tempDynamicFilters: Array<SelectedFilter> = []
    const flattedDynamicFilters: Array<DynamicFilterModel> = []

    const tempStaticFilters = filters

    dynamicFilters.forEach(filter => {
      flattedDynamicFilters.push({
        ...filter,
        options: flattenDynamicFilterOptions(filter.options),
      })
    })

    selectedDynamicFilters.forEach(selectedFilter => {
      selectedFilter.ids.forEach(id => {
        const dynamicFilter = flattedDynamicFilters.find(
          filter => filter.type === selectedFilter.type,
        )
        const filter = dynamicFilter?.options.find(item => item.id === id)
        if (filter) {
          tempDynamicFilters.push({
            name: selectedFilter.type,
            label: filter.title,
            value: filter.id,
            isDynamic: true,
          })
        }
      })
    })

    return [...tempStaticFilters, ...tempDynamicFilters]
  }

  render() {
    const selectedFilters = this.getSelectedFilters()

    return (
      <div className="u-flexbox u-align-items-center u-flex-wrap">
        {selectedFilters.map(this.renderFilter)}

        <div className="u-margin-left-auto">
          <FilterReset />
        </div>
      </div>
    )
  }
}

const SelectedFiltersWithIntl = injectIntl(SelectedFilters)

export { SelectedFiltersWithIntl as SelectedFilters }
export default connector(SelectedFiltersWithIntl)
