'use client'

import { ReactComponentElement, ReactNode } from 'react'

import { Cell, Label, Text, Checkbox, Radio } from '@vinted/web-ui'

import SelectableItemList from 'components/SelectableItemList'
import { SelectableListItem } from 'types/components'
import { FilterGroupModel } from 'types/models/filter-group'

export type RenderItemProps<T> = {
  itemElementProps: ComponentProps<typeof Cell>
  isSelected: boolean
  item: SelectableListItem<T>
  button: ReactComponentElement<typeof Checkbox> | ReactComponentElement<typeof Radio>
}

type Props<T> = {
  groups: Array<FilterGroupModel<T>>
  selectedIds: Array<number>
  name: string
  isMultiSelect: boolean
  onItemClick: (itemId: number) => void
  renderItem?: (props: RenderItemProps<T>) => ReactNode
}

const FilterGroupList = <T,>({
  groups,
  selectedIds,
  name,
  isMultiSelect,
  onItemClick,
  renderItem,
}: Props<T>) => {
  const handleItemClick = (item: SelectableListItem<T>) => {
    onItemClick(Number(item.id))
  }

  const renderGroup = (group: FilterGroupModel<T>, index: number) => {
    const labelType = index === 0 ? Label.Type.Leading : Label.Type.Stacked
    const items = group.items.map(({ id, title, data }) => ({
      id,
      title,
      value: id,
      data,
    }))

    return (
      <div key={group.id}>
        <Label
          text={<Text text={group.description} type={Text.Type.Subtitle} />}
          type={labelType}
        />
        <SelectableItemList
          name={`${name}_${String(group.id)}`}
          isMultiSelect={isMultiSelect}
          items={items}
          selected={selectedIds}
          renderItem={renderItem}
          onItemClick={handleItemClick}
          itemButtonTestId="filter-group-list"
          disableButtonClicks
        />
      </div>
    )
  }

  return <>{groups.map((group, index) => renderGroup(group, index))}</>
}

export default FilterGroupList
