'use client'

import { ArrowLeft24 } from '@vinted/monochrome-icons'
import { Button, Divider, Icon, Navigation, Spacer, Text } from '@vinted/web-ui'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import uuid from 'uuid'

import FilterCatalogList from 'components/FilterCatalogList/FilterCatalogList'
import FilterDropdown from 'components/FilterDropdown'
import ScrollableArea from 'components/ScrollableArea'
import { OptionType } from 'constants/dynamic-filters'
import { GRID_FILTER_DROPDOWN_MIN_WIDTH, GRID_FILTER_DROPDOWN_MAX_HEIGHT } from 'constants/filter'
import useTranslate from 'hooks/useTranslate'
import { transformDynamicFilterNavigationalOptions } from 'state/catalog-filters/transformers'
import { DynamicFilterOptionModel, ExperimentModel } from 'types/models/dynamic-filter'
import { FilterCatalogModel } from 'types/models/filter-catalog'

import useTracking from 'hooks/useTracking'
import {
  abTestExposeEvent,
  userClickDynamicFilterOptionEvent,
  userViewDynamicFilterOptionEvent,
} from 'libs/common/event-tracker/events'
import { flattenDynamicFilterOptions } from 'state/catalog-filters/utils'
import { getGlobalSearchSessionId, getSearchSessionId } from 'state/catalog-filters/selectors'
import { getExposee } from 'state/session/selectors'
import { Screen } from 'constants/tracking/screens'

import VerticalPadder from '../Common/VerticalPadder'
import GridGroup from '../Common/GridGroup'

type Props = {
  type: string
  title: string
  isSelectionHighlighted: boolean
  rootOptionType: OptionType
  isHidden?: boolean
  experiment?: ExperimentModel
  options: Array<DynamicFilterOptionModel>
  selectedIds: Array<number>
  fromScreen: Screen
  onListOptionSelect: (id: number) => void
}

const GridFilterDropdown = ({
  options,
  rootOptionType,
  selectedIds,
  isHidden,
  experiment,
  title,
  type,
  isSelectionHighlighted,
  fromScreen,
  onListOptionSelect,
}: Props) => {
  const translate = useTranslate()
  const { track } = useTracking()
  const exposee = useSelector(getExposee)

  const [selectedCatalogCode, setSelectedCatalogCode] = useState('')
  const [highlightedSelectedIds, setHighlightedSelectedIds] = useState<Array<number>>(selectedIds)

  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const searchSessionId = useSelector(getSearchSessionId)

  const seenItems = useRef(new Set<number>())

  useEffect(() => {
    if (experiment) {
      track(abTestExposeEvent({ ...exposee, ...experiment }))
    }
  }, [experiment, exposee, track])

  useEffect(() => {
    if (rootOptionType === OptionType.Navigational) {
      setSelectedCatalogCode('')

      return
    }

    setSelectedCatalogCode(OptionType.Root)
  }, [rootOptionType])

  const filteringSessionId = useMemo(() => uuid.v4(), [])

  const flattedOptions = useMemo(() => {
    return flattenDynamicFilterOptions(options)
  }, [options])

  const navigationalItems = useMemo(() => {
    return transformDynamicFilterNavigationalOptions(
      options,
      rootOptionType,
      isSelectionHighlighted,
      highlightedSelectedIds,
    )
  }, [options, rootOptionType, isSelectionHighlighted, highlightedSelectedIds])

  const navigatedRoot = useMemo(() => {
    return options.find(item => selectedCatalogCode === `${item.type}_${item.id}`)
  }, [options, selectedCatalogCode])

  const navigatedGroups = useMemo(() => {
    if (selectedCatalogCode === OptionType.Root) return options

    return navigatedRoot?.options || []
  }, [navigatedRoot?.options, options, selectedCatalogCode])

  const navigatedGroupsSelected = useMemo(() => {
    return navigatedGroups
      ?.map(item => ({
        ...item,
        options: item.options.filter(option => highlightedSelectedIds.includes(option.id)),
      }))
      .filter(item => item.options.length > 0)
  }, [highlightedSelectedIds, navigatedGroups])

  const groupToCount = useMemo(() => {
    const map = new Map<number, number>()

    navigatedGroups?.forEach(group => {
      let count = 0

      group.options.forEach(item => {
        if (selectedIds.includes(item.id)) {
          count += 1
        }
      })

      map.set(group.id, count)
    })

    return map
  }, [navigatedGroups, selectedIds])

  const getPosition = (id: number) => flattedOptions.findIndex(item => item.id === id) + 1

  const handleNavClick = (catalog: FilterCatalogModel) => {
    setSelectedCatalogCode(catalog.code)
  }

  const handleNavBackClick = () => {
    setSelectedCatalogCode('')
    setHighlightedSelectedIds(selectedIds)
  }

  const handleClose = () => {
    seenItems.current.clear()
    setHighlightedSelectedIds(selectedIds)
  }

  const handleSelect = (item: DynamicFilterOptionModel) => {
    onListOptionSelect(item.id)

    track(
      userClickDynamicFilterOptionEvent({
        position: getPosition(item.id),
        // negating here because state updates are async
        isSelected: !selectedIds.includes(item.id),
        id: item.id,
        itemsCount: item.itemsCount,
        type,
        code: type,
        filteringSessionId,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )
  }

  const handleInViewItem = (item: DynamicFilterOptionModel) => {
    if (seenItems.current.has(item.id)) return
    seenItems.current.add(item.id)

    track(
      userViewDynamicFilterOptionEvent({
        position: getPosition(item.id),
        id: item.id,
        itemsCount: item.itemsCount,
        type,
        code: type,
        filteringSessionId,
        globalSearchSessionId,
        searchSessionId,
        fromScreen,
      }),
    )
  }

  const renderNavigation = () => {
    if (!navigatedRoot) {
      return undefined
    }

    return (
      <>
        <Navigation
          body={navigatedRoot.title}
          left={
            <Button
              styling={Button.Styling.Flat}
              theme="amplified"
              icon={<Icon name={ArrowLeft24} />}
              onClick={handleNavBackClick}
              testId={`${type}-dropdown-go-back`}
              aria={{
                'aria-label': translate('catalog.filters.a11y.actions.back'),
              }}
            />
          }
        />
        <Divider />
      </>
    )
  }

  const renderHeader = () => {
    if (!selectedCatalogCode) return undefined
    if ((navigatedGroupsSelected?.length || 0) < 1) return undefined
    if (!isSelectionHighlighted) return undefined

    return (
      <>
        <Spacer size={Spacer.Size.Large} />
        <VerticalPadder>
          <Text
            text={translate('catalog.filters.dynamic.applied_filters')}
            type={Text.Type.Subtitle}
          />
        </VerticalPadder>

        {navigatedGroupsSelected?.map(gridItem => (
          <GridGroup
            key={gridItem.id}
            option={gridItem}
            selectedIds={selectedIds}
            onSelect={handleSelect}
            onInViewItem={handleInViewItem}
          />
        ))}

        <Spacer size={Spacer.Size.Large} />
        <Divider />
      </>
    )
  }

  const renderDropdown = () => {
    if (!selectedCatalogCode) {
      return <FilterCatalogList catalogs={navigationalItems} onItemClick={handleNavClick} />
    }

    return navigatedGroups?.map(gridItem => (
      <GridGroup
        key={gridItem.id}
        isSticky
        selectedCount={groupToCount.get(gridItem.id)}
        option={gridItem}
        selectedIds={selectedIds}
        onSelect={handleSelect}
        onInViewItem={handleInViewItem}
      />
    ))
  }

  if (isHidden) return null

  return (
    <div className="u-ui-margin-right-regular u-ui-margin-bottom-regular">
      <FilterDropdown
        title={title}
        onClose={handleClose}
        minWidth={GRID_FILTER_DROPDOWN_MIN_WIDTH}
        testId={`catalog--${type}-filter`}
        areAnyOfItemsSelected={selectedIds.length > 0}
        useChipAsTrigger
      >
        <div className="u-ui-padding-vertical-x-small">
          {renderNavigation()}
          <ScrollableArea maxHeight={GRID_FILTER_DROPDOWN_MAX_HEIGHT}>
            {renderHeader()}
            {renderDropdown()}

            {selectedCatalogCode && <Spacer size={Spacer.Size.Large} />}
          </ScrollableArea>
        </div>
      </FilterDropdown>
    </div>
  )
}

export default GridFilterDropdown
