'use client'

import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { reloadPage } from 'libs/utils/window'

import { State as AppState } from 'state/types'
import {
  getDynamicFilters,
  getSelectedDynamicFilters,
  getCatalogIds,
} from 'state/catalog-filters/selectors'

import { DisplayType, OptionType, SelectionType } from 'constants/dynamic-filters'
import { DynamicFilterModel } from 'types/models/dynamic-filter'

import { Screen } from 'constants/tracking/screens'
import { actions } from 'state/catalog-filters/slice'

import ListFilterDropdown from 'components/DynamicFilters/ListFilter/ListFilterDropdown'
import SearchFilterDropdown from 'components/DynamicFilters/SearchFilter/SearchFilterDropdown'
import GridFilterDropdown from 'components/DynamicFilters/GridFilter/GridFilterDropdown'

import CatalogFilter from './CatalogFilter'
import PriceFilter from './PriceFilter'
import SortFilter from './SortFilter'

const mapStateToProps = (state: AppState) => ({
  catalogIds: getCatalogIds(state),
  dynamicFilters: getDynamicFilters(state),
  selectedDynamicFilters: getSelectedDynamicFilters(state),
})

const mapActionsToProps = {
  setSelectedDynamicFilter: actions.setSelectedDynamicFilter,
  removeSelectedDynamicFilterId: actions.removeSelectedDynamicFilterId,
}

const connector = connect(mapStateToProps, mapActionsToProps)

type OwnProps = {
  learnMoreUrl?: string | null
}

type Props = ConnectedProps<typeof connector> & OwnProps

class CatalogFilters extends Component<Props> {
  handleDynamicFilterToggle(
    id: number,
    type: string,
    selectionType: SelectionType,
    selectedIds: Array<number>,
  ) {
    const { setSelectedDynamicFilter, removeSelectedDynamicFilterId } = this.props

    if (!selectedIds.includes(id)) {
      setSelectedDynamicFilter({
        selectedDynamicFilter: {
          type,
          ids: selectionType === SelectionType.Single ? [id] : [...selectedIds, id],
        },
      })
    } else if (selectionType === SelectionType.Multi) {
      removeSelectedDynamicFilterId({
        type,
        id,
      })
    }
  }

  // TODO: convert into functional component
  // eslint-disable-next-line class-methods-use-this
  getRootOptionType(dynamicFilter: DynamicFilterModel) {
    return dynamicFilter.options.find(option => !!option.type)?.type || OptionType.Default
  }

  getSelectedFilter(type: string) {
    return this.props.selectedDynamicFilters.find(filter => filter.type === type)
  }

  renderDynamicFilter(filter: DynamicFilterModel) {
    const selectedDynamicFilter = this.getSelectedFilter(filter.type)
    const key = `${filter.type}-${filter.id}`

    switch (filter.displayType) {
      case DisplayType.List: {
        return (
          <ListFilterDropdown
            key={key}
            type={filter.type}
            title={filter.title}
            selectionType={filter.selectionType}
            isSelectionHighlighted={filter.isSelectionHighlighted}
            isHidden={filter.isHidden}
            experiment={filter.experiment}
            rootOptionType={this.getRootOptionType(filter)}
            options={filter.options}
            fromScreen={Screen.Catalog}
            selectedIds={selectedDynamicFilter?.ids || []}
            onListOptionSelect={id =>
              this.handleDynamicFilterToggle(
                id,
                filter.type,
                filter.selectionType,
                selectedDynamicFilter?.ids || [],
              )
            }
          />
        )
      }
      case DisplayType.ListSearch: {
        return (
          <SearchFilterDropdown
            key={key}
            type={filter.type}
            title={filter.title}
            selectionType={filter.selectionType}
            isSelectionHighlighted={filter.isSelectionHighlighted}
            isHidden={filter.isHidden}
            experiment={filter.experiment}
            rootOptionType={this.getRootOptionType(filter)}
            options={filter.options}
            fromScreen={Screen.Catalog}
            translations={filter.searchTranslations}
            selectedIds={selectedDynamicFilter?.ids || []}
            onListOptionSelect={id =>
              this.handleDynamicFilterToggle(
                id,
                filter.type,
                filter.selectionType,
                selectedDynamicFilter?.ids || [],
              )
            }
          />
        )
      }
      case DisplayType.Grid: {
        return (
          <GridFilterDropdown
            key={key}
            type={filter.type}
            title={filter.title}
            fromScreen={Screen.Catalog}
            isSelectionHighlighted={filter.isSelectionHighlighted || false}
            isHidden={filter.isHidden}
            experiment={filter.experiment}
            rootOptionType={this.getRootOptionType(filter)}
            options={filter.options}
            selectedIds={selectedDynamicFilter?.ids || []}
            onListOptionSelect={id =>
              this.handleDynamicFilterToggle(
                id,
                filter.type,
                filter.selectionType,
                selectedDynamicFilter?.ids || [],
              )
            }
          />
        )
      }
      case DisplayType.HybridPrice: {
        return <PriceFilter key={key} minimumValidation={filter.minimumValidation} />
      }
      default:
        return null
    }
  }

  renderDynamicFilters() {
    const { dynamicFilters } = this.props

    return dynamicFilters.map(filter => this.renderDynamicFilter(filter))
  }

  componentDidMount() {
    window.addEventListener('popstate', reloadPage)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', reloadPage)
  }

  render() {
    const { learnMoreUrl } = this.props

    return (
      <div className="u-flexbox u-flex-wrap">
        <CatalogFilter />
        {this.renderDynamicFilters()}
        <SortFilter learnMoreUrl={learnMoreUrl} />
      </div>
    )
  }
}

export default connector(CatalogFilters)
