'use client'

import { Text, Cell, Spacer } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'
import { useState } from 'react'

import { DynamicFilterOptionModel } from 'types/models/dynamic-filter'

import VerticalPadder from '../VerticalPadder'
import GridOption from './GridOption'

type Props = {
  option: DynamicFilterOptionModel
  selectedIds: Array<number>
  isSticky?: boolean
  selectedCount?: number
  onSelect: (option: DynamicFilterOptionModel) => void
  onInViewItem?: (option: DynamicFilterOptionModel) => void
  onInViewGroup?: (group: DynamicFilterOptionModel) => void
}

const GridGroup = ({
  option,
  selectedCount,
  onSelect,
  selectedIds,
  isSticky,
  onInViewItem,
  onInViewGroup,
}: Props) => {
  const [shouldStick, setShouldStick] = useState(true)

  const { ref: groupRef } = useInView({
    onChange: inView => {
      setShouldStick(inView)

      if (!inView) {
        return
      }

      onInViewGroup?.(option)
    },
  })

  const renderTitle = () => {
    if (selectedCount === undefined) return option.title

    return (
      <Text type={Text.Type.Title} width={Text.Width.Parent}>
        {option.title}{' '}
        {selectedCount !== 0 && (
          <Text type={Text.Type.Caption} as="span" theme="primary">
            ({selectedCount})
          </Text>
        )}
      </Text>
    )
  }

  return (
    <>
      <div className={isSticky && shouldStick ? 'u-sticky-top' : undefined}>
        <Cell fullWidthTitle title={renderTitle()} />
      </div>

      <VerticalPadder>
        <div ref={groupRef} className="u-flexbox u-flex-wrap u-gap-medium">
          {option.options.map(item => (
            <GridOption
              key={item.id}
              option={item}
              onClick={onSelect}
              onInView={onInViewItem}
              activated={selectedIds.includes(item.id)}
            />
          ))}
        </div>
      </VerticalPadder>

      <Spacer size={Spacer.Size.Large} />
    </>
  )
}

export default GridGroup
